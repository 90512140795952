<template>
  <el-row>
    <el-col style="padding-right: 10px">
      <!-- <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <el-select
        v-model="value"
        placeholder="全部"
        clearable
        filterable
        @blur="selectBlur"
        @clear="selectClear"
        @change="selectChange"
        v-loadmore="loadMore"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.label"
          :value="item.marketName"
        />
      </el-select>
    </el-col>

    <el-col>
      <div class="graph">
        <el-card style="height: 320px; width: 50%; margin-right: 1.1%">
          <div style="color: #000; font-weight: bold">设备运行情况</div>
          <div
            style="
              height: 240px;
              width: 100%;
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            "
          >
            <div
              class="device"
              v-for="item in deviceData"
              :key="item.name"
              :body-style="{ display: 'flex', padding: 0 }"
            >
              <i
                class="icon"
                :class="`el-icon-${item.icon}`"
                :style="{ background: item.color }"
              ></i>
              <div class="detail">
                <p class="desc">{{ item.value }}</p>
                <div class="detail1">
                  <p class="desc">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card style="height: 320px; width: 50%; margin-right: 5px">
          <div ref="echarts2" style="height: 300px; margin: -1%"></div>
        </el-card>
      </div>
    </el-col>

    <el-col>
      <div class="graph">
        <el-card style="height: 430px; width: 49.3%">
          <div class="manage">
            <el-dialog
              :visible.sync="markdialogVisible"
              width="40%"
              :before-close="markhandleClose"
              :close-on-click-modal="false"
            >
              <div
                slot="title"
                class="header-title"
                :style="{
                  background: 'white',
                  color: 'black',
                  'font-weight': 'bold',
                }"
              >
                <div
                  style="
                    border-left: 4px solid rgb(54, 161, 233);
                    width: 90px;
                    font-weight: bold;
                    text-align: center;
                  "
                >
                  原因登记
                </div>
              </div>
              <!-- 用户的表单信息 -->
              <el-form
                ref="jiluform"
                :rules="jilurules"
                :model="jiluform"
                label-width="160px"
              >
                <el-form-item label="登记描述：" prop="abnormalHandleMessage">
                  <el-input
                    placeholder="请登记原因"
                    v-model="jiluform.abnormalHandleMessage"
                  ></el-input>
                </el-form-item>
              </el-form>

              <span slot="footer" class="dialog-footer">
                <el-button @click="jilucancel">取 消</el-button>
                <el-button type="primary" @click="jilusubmit">确 定</el-button>
              </span>
            </el-dialog>

            <el-dialog
              :visible.sync="dialogVisible"
              width="70%"
              :before-close="handleClose"
              :close-on-click-modal="false"
            >
              <el-descriptions
                class="margin-top"
                title="设备详情"
                :column="3"
                :size="size"
                style="margin-top: -40px"
                :labelStyle="rowCenter"
                border
              >
                <el-descriptions-item>
                  <template slot="label"> 设备序列 </template>
                  {{ tableLabel.scaleMac }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 设备类型 </template>
                  {{ tableLabel.scaleType }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 设备型号 </template>
                  {{ tableLabel.scaleInfo }}
                </el-descriptions-item>

                <el-descriptions-item>
                  <template slot="label"> 设备状态 </template>

                  <el-tag size="medium" :type="colorData(tableLabel.status)">{{
                    tableLabel.status == 1
                      ? "正常"
                      : tableLabel.status == 0
                      ? "未激活"
                      : tableLabel.status == 2
                      ? "待绑定"
                      : tableLabel.status == 5
                      ? "异常"
                      : tableLabel.status == 3
                      ? "送检中"
                      : tableLabel.status == 4
                      ? "送检中"
                      : ""
                  }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 最新上传订单时间 </template>
                  {{ tableLabel.lastUploadTime }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 所属市场 </template>
                  {{ tableLabel.marketName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 市场负责人 </template>
                  {{ tableLabel.marketLinkName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 联系电话 </template>
                  {{ tableLabel.marketLinkNO }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 店铺名称 </template>
                  {{ tableLabel.shopName }}
                </el-descriptions-item>

                <el-descriptions-item>
                  <template slot="label"> 商户姓名 </template>
                  {{ tableLabel.nickname }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 联系电话 </template>
                  {{ tableLabel.shopTelephoneNo }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label"> 店铺商品类别 </template>
                  {{
                    tableLabel.shopType == 1
                      ? "蔬菜类"
                      : tableLabel.shopType == 2
                      ? "水果类"
                      : tableLabel.shopType == 3
                      ? "猪肉类"
                      : tableLabel.shopType == 4
                      ? "牛肉类"
                      : tableLabel.shopType == 5
                      ? "水产类"
                      : tableLabel.shopType == 6
                      ? "海鲜类"
                      : tableLabel.shopType == 7
                      ? "冰鲜类"
                      : tableLabel.shopType == 8
                      ? "干货类"
                      : tableLabel.shopType == 9
                      ? "面食类"
                      : tableLabel.shopType == 10
                      ? "卤菜类"
                      : ""
                  }}
                </el-descriptions-item>

                <el-descriptions-item>
                  <template slot="label"> 最新年检时间 </template>
                  {{ tableLabel.scaleCheckDate }}
                </el-descriptions-item>

                <el-descriptions-item>
                  <template slot="label"> 年检有效期 </template>
                  {{ tableLabel.scaleNextCheckDate }}
                </el-descriptions-item>

                <!-- <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-tickets"></i>
                    备注
                  </template>
                  <el-tag size="small">学校</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-office-building"></i>
                    联系地址
                  </template>
                  江苏省苏州市吴中区吴中大道 1188 号
                </el-descriptions-item> -->
              </el-descriptions>

              <el-tag style="margin-top: 20px" effect="dark">登记记录</el-tag>
              <h1
                style="
                  width: 100%;
                  height: 1px;
                  background: #ededed;
                  margin: -0px;
                "
              ></h1>

              <div class="block" style="margin-top: 20px">
                <el-scrollbar
                  style="height: 300px"
                  wrap-style="overflow-x:hidden;"
                >
                  <el-timeline>
                    <el-timeline-item
                      v-for="(activity, index) in activities"
                      :key="index"
                      size="large"
                      type="primary"
                      icon="el-icon-more"
                      placement="top"
                      :timestamp="
                        activity.abnormalRecordTime +
                        ' ' +
                        activity.abnormalMessage
                      "
                    >
                      <div
                        v-show="!activity.abnormalHandleMessage"
                        style="display: flex; align-items: center"
                      >
                        <div>登记描述：</div>
                        <div style="color: red; margin-right: 10px">待处理</div>
                        <el-button
                          type="danger"
                          size="mini"
                          @click="toSetmark(activity)"
                          style="right: 0; margin-top: 5px"
                          >登记原因</el-button
                        >
                      </div>

                      <div v-show="activity.abnormalHandleMessage">
                        {{
                          "登记描述：" +
                          "&#8194;" +
                          activity.abnormalHandleMessage
                        }}
                        <el-button
                          type="danger"
                          plain
                          style="margin-left: 10px;"
                          size="mini"
                          v-show="
                            !activity.hasOwnProperty('abnormalHandleTime')
                              ? true
                              : false
                          "
                          @click="onClear(activity)"
                          >清除异常</el-button
                        >
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </el-scrollbar>
              </div>
            </el-dialog>
          </div>

          <div style="color: #000; font-weight: bold; margin-bottom: 3px">
            年检设备
          </div>
          <el-table
            :data="tableData"
            style="width: 100%"
            max-height="430"
            :header-cell-style="{
              color: '#000',
              fontSize: '14px',
              backgroundColor: '#ddd',
            }"
          >
            <el-table-column
              prop="scaleMac"
              label="设备序列"
              width="220"
              align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.scaleMac +
                  "_" +
                  JSON.parse(scope.row.scaleName).info +
                  "_" +
                  scope.row.scaleType
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="marketName"
              label="所属市场"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="shopName"
              label="所属店铺"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="shopTelephoneNo"
              label="联系电话"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="scaleNextCheckDate"
              label="年检有效期"
              :width="flexColumnWidth('年检有效期', 'scaleNextCheckDate')"
            >
              <template slot-scope="scope">
                {{ scope.row.scaleNextCheckDate }}

                <el-tag
                  size="medium"
                  type="danger"
                  style="margin-top: 5px"
                  v-show="getnianjianshow(scope.row)"
                  >{{ getnianjiantime(scope.row) }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  @click="tabledetail(scope.row)"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card style="height: 430px; width: 49.3%; margin-right: 5px">
          <div style="color: #000; font-weight: bold; margin-bottom: 3px">
            异常设备
          </div>
          <el-table
            :data="deviceMarket"
            style="width: 100%"
            max-height="430"
            :header-cell-style="{
              color: '#000',
              fontSize: '14px',
              backgroundColor: '#ddd',
            }"
          >
            <el-table-column
              prop="scaleMac"
              label="设备序列"
              width="220"
              align="center"
            >
              <template slot-scope="scope">
                {{
                  scope.row.scaleMac +
                  "_" +
                  JSON.parse(scope.row.scaleName).info +
                  "_" +
                  scope.row.scaleType
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="marketName"
              label="所属市场"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="shopName"
              label="所属店铺"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="shopTelephoneNo"
              label="联系电话"
              width="120"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="scaleNextCheckDate"
              label="异常信息"
              :width="flexColumnWidth1('异常信息', 'scaleNextCheckDate')"
            >
              <template slot-scope="scope">
                {{ scope.row.scaleNextCheckDate }}

                <el-tag
                  size="medium"
                  type="danger"
                  style="margin-top: 5px"
                  v-show="getnianjianshow(scope.row)"
                  >{{ getnianjiantime(scope.row) }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button
                  @click="tabledetail(scope.row)"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>
  <script>
import { getData } from "../../api";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
export default {
  data() {
    return {
      activities: [{}],
      rowCenter: {
        "text-align": "center",
      },
      options: [],
      tableData: [],
      tableLabel: {
        name: "设备",
        market: "所属市场",
        shop: "所属店铺",
        phone: "联系电话",
        code: "年检信息",
        mark: "操作",
      },
      
      deviceMarket: [], //异常设备
      countData: [],
      deviceData: [],
      dayCount: "",
      yearMonthCount: "",
      dialogVisible: false,
      markdialogVisible: false,
      jiluform: {
        abnormalHandleMessage: "",
      },
      jilurules: {
        abnormalHandleMessage: [{ required: true, message: "请输入记录" }],
      },
    };
  },

  methods: {

    onClear(row) {
      console.dir("清除", row);
      this.$confirm("确定要清除本次异常么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var getTime = new Date().getTime(); //获取到当前时间戳
          var time = new Date(getTime); //创建一个日期对象
          function nowDate(time) {
            var year = time.getFullYear(); // 年
            var month = (time.getMonth() + 1).toString().padStart(2, "0"); // 月
            var date = time.getDate().toString().padStart(2, "0"); // 日
            var hour = time.getHours().toString().padStart(2, "0"); // 时
            var minute = time.getMinutes().toString().padStart(2, "0"); // 分
            var second = time.getSeconds().toString().padStart(2, "0"); // 秒
            return (
              year +
              "-" +
              month +
              "-" +
              date +
              " " +
              hour +
              ":" +
              minute +
              ":" +
              second
            );
          }
          var data = {
            token: this.$cookie.get("token"),
            abnormalHandleTime: nowDate(time),
            abnormalRecordId: row.abnormalRecordId,
            marketId: row.marketId,
            scaleMac: row.scaleMac,
          };
          this.$api.handleAbnormalDeviceMessage(data).then((res) => {
            console.dir("清除原因数据：", res);
            if (res.status === 200 && res.data.codeId === 1) {
              this.listAbnormalScaleMessageByPage(row.marketId, row.scaleMac);
            } else {
              if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },


    toSetmark(row) {
      console.dir("登记弹窗", row);
      this.tableLabel = row;
      this.markdialogVisible = true;
    },
    // 弹窗关闭的时候
    markhandleClose() {
      this.$refs.jiluform.resetFields();
      this.markdialogVisible = false;
    },
    jilucancel() {
      this.$refs.jiluform.resetFields();
      this.markhandleClose();
    },
    jilusubmit() {
      console.log("登记弹窗", "valid");
      this.$refs.jiluform.validate((valid) => {
        console.log("登记弹窗", valid);
        if (valid) {
          // 后续对表单数据的处理
          //date1结束时间
          let date1 = new Date(this.tableLabel.lastUploadTime);
          //date2当前时间
          let date2 = new Date();
          date1 = new Date(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate()
          );
          date2 = new Date(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate()
          );

          const diff = date2.getTime() - date1.getTime(); //目标时间减去当前时间
          const diffDate = diff / (24 * 60 * 60 * 1000); //计算当前时间与结束时间之间相差天数
          var abnormalMessage = diffDate + "天未上传订单";
          var data = {
            token: this.$cookie.get("token"),
            scaleMac: this.tableLabel.scaleMac,
            // abnormalRecordId:this.tableLabel.abnormalRecordId,
            abnormalMessage: abnormalMessage,
            marketId: this.tableLabel.marketId,
            abnormalHandleMessage: this.jiluform.abnormalHandleMessage,
            uuid: uuidv4(),
          };
          this.$api.setAbnormalScale(data).then((res) => {
            console.dir("原因数据：", res);
            if (res.status === 200 && res.data.codeId === 1) {
            } else {
              if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
            }
          });

          // 清空表单的数据
          this.$refs.jiluform.resetFields();
          // 关闭弹窗
          this.markdialogVisible = false;
        }
      });
    },

    setEachrts2Option(monthOryear) {
      if (monthOryear === "month") {
        var data = {
          uploadYearMonth: 202405,
          token: this.$cookie.get("token"),
        };
        this.$api.listEquitableRecordByMonth(data).then((res) => {
          console.dir("监管月图表", res);
          if (res.status === 200 && res.data.codeId === 1) {
          } else {
            if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
          }
        });
      } else {
      }

      const echarts2 = echarts.init(this.$refs.echarts2);
      const eachrts2Option = {
        title: {
          text: "公平秤使用数据",
          textStyle: {
            fontSize: 16,
            color: "#000000",
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: "line",
          },
        ],
      };

      echarts2.setOption(eachrts2Option);
    },

    colorData(code) {
      if (code === 0) {
        return "info";
      } else if (code === 1) {
        return "success";
      } else {
        return "danger";
      }
    },
    // 弹窗关闭的时候
    handleClose() {
      this.dialogVisible = false;
    },
    tabledetail(row) {
      console.dir("弹窗", row);
      this.tableLabel = row;
      this.dialogVisible = true;
      this.listAbnormalScaleMessageByPage(row.marketId, row.scaleMac);
    },

    listAbnormalScaleMessageByPage(marketId, scaleMac) {
      var data = {
        marketId: marketId,
        scaleMac: scaleMac,
        token: this.$cookie.get("token"),
        page: 1,
        size: 10,
      };
      this.$api.listAbnormalScaleMessageByPage(data).then((res) => {
        console.dir("登记记录", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.activities = res.data.dataObject.data;
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },

    /**
     * el-table-column 自适应列宽
     * @param prop_label: 表名
     * @param table_data: 表格数据
     */
    flexColumnWidth(label, prop) {
      // console.log('label', label)
      // console.log('prop', prop)
      // 1.获取该列的所有数据
      const arr = this.tableData.map((x) => x[prop]);
      arr.push(label); // 把每列的表头也加进去算
      // console.log(arr)
      // 2.计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
      return this.getMaxLength(arr) + 25 + "px";
    },
    /**
     * el-table-column 自适应列宽
     * @param prop_label: 表名
     * @param table_data: 表格数据
     */
     flexColumnWidth1(label, prop) {
      // console.log('label', label)
      // console.log('prop', prop)
      // 1.获取该列的所有数据
      const arr = this.deviceData.map((x) => x[prop]);
      arr.push(label); // 把每列的表头也加进去算
      // console.log(arr)
      // 2.计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
      return this.getMaxLength(arr) + 25 + "px";
    },
    /**
     * 遍历列的所有内容，获取最宽一列的宽度
     * @param arr
     */
    getMaxLength(arr) {
      return arr.reduce((acc, item) => {
        if (item) {
          const calcLen = this.getTextWidth(item);
          if (acc < calcLen) {
            acc = calcLen;
          }
        }
        return acc;
      }, 0);
    },
    /**
     * 使用span标签包裹内容，然后计算span的宽度 width： px
     * @param valArr
     */
    getTextWidth(str) {
      let width = 0;
      const html = document.createElement("span");
      html.innerText = str;
      html.className = "getTextWidth";
      document.querySelector("body").appendChild(html);
      width = document.querySelector(".getTextWidth").offsetWidth;
      document.querySelector(".getTextWidth").remove();
      return width;
    },
    loadMore() {
      // 在这里请求接口加载数据
      console.log("滚动到底部了");
    },
    selectBlur(e) {
      // 意见类型
      if (e.target.value !== "") {
        this.value = e.target.value + "(其他)";
        this.$forceUpdate(); // 强制更新
      }
    },
    selectClear() {
      this.value = "";
      this.$forceUpdate();
      this.countDevices("");
      this.listSellerCheckScale("", 1, 10);
      this.listSellerScaleJoinView("", 1, 10);
    },
    selectChange(val) {
      this.value = val;

      this.$forceUpdate();
      let result = [];
      this.deviceData = [];
      this.tableData = [];
      this.deviceMarket = [];
      if (val === "全部") {
        this.countDevices("");
        this.listSellerCheckScale("", 1, 10);
        this.listSellerScaleJoinView("", 1, 10);
      } else {
        for (let item of this.options) {
          if (item.marketName === val) {
            result.push(item);
          }
        }
        this.countDevices(result[0].marketId);
        this.listSellerCheckScale(result[0].marketId, 1, 10);
        this.listSellerScaleJoinView(result[0].marketId, 1, 10);
      }
    },

    listMarkets(page, size) {
      var data = {
        token: this.$cookie.get("token"),
        page: page,
        size: size,
      };
      this.$api.listMarkets(data).then((res) => {
        console.dir("市场数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.options = res.data.dataObject.data;
          this.options.unshift({ marketName: "全部" });
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },
    listSellerCheckScale(marketId, page, size) {
      var data = {
        token: this.$cookie.get("token"),
        marketId: marketId,
        page: page,
        size: size,
      };
      this.$api.listSellerCheckScale(data).then((res) => {
        console.dir("市场设备数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.tableData = res.data.dataObject.data;
          for (var i = 0; i < this.tableData.length; i++) {
            // this.tableData[i].scaleMac =
            //   this.tableData[i].scaleMac +
            //   "_" +
            //   JSON.parse(this.tableData[i].scaleName).info +
            //   "_" +
            //   this.tableData[i].scaleType;
          }
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },

    listSellerScaleJoinView(marketId, page, size) {
      var data = {
        token: this.$cookie.get("token"),
        lastUploadTime:"2024-06-05",
        marketId: marketId,
        status:5,
        uploadTimeOrStatusCondition:1,
        page: page,
        size: size,
      };

      this.$api.listSellerScaleJoinView(data).then((res) => {
        console.dir("设备列表", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.deviceMarket = res.data.dataObject.data;
          for (var i = 0; i < this.deviceMarket.length; i++) {

          }
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },

    getnianjianshow(row) {
      if (typeof row.scaleNextCheckDate == "undefined") {
        return false;
      } else {
        //date1结束时间
        let date1 = new Date(row.scaleNextCheckDate);
        //date2当前时间
        let date2 = new Date();
        date1 = new Date(
          date1.getFullYear(),
          date1.getMonth(),
          date1.getDate()
        );
        date2 = new Date(
          date2.getFullYear(),
          date2.getMonth(),
          date2.getDate()
        );
        const diff = date2.getTime() - date1.getTime(); //目标时间减去当前时间
        const diffDate = diff / (24 * 60 * 60 * 1000); //计算当前时间与结束时间之间相差天数
        if (diffDate === 0 || diffDate < 0) {
          return false;
        } else {
          return true;
        }
      }
    },

    getnianjiantime(row) {
      console.dir("年检", row);
      //date1结束时间
      let date1 = new Date(row.scaleNextCheckDate);
      //date2当前时间
      let date2 = new Date();
      date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
      date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
      const diff = date2.getTime() - date1.getTime(); //目标时间减去当前时间
      const diffDate = diff / (24 * 60 * 60 * 1000); //计算当前时间与结束时间之间相差天数
      return "已超期年检" + diffDate + "天";
    },

    countDevices(marketId) {
      console.dir("请求设备数据");
      var data = {
        token: this.$cookie.get("token"),
        marketId: marketId,
      };

      this.$api.countDevices(data).then((res) => {
        console.dir("设备总数：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          const item = {
            name: "设备总数（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.scaleCount,
          };
          const item1 = {
            name: "正常运行设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.normalScaleCount,
          };
          const item2 = {
            name: "异常运行设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.abnormalScaleCount,
          };
          const item3 = {
            name: "年检超期设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.checkTimeOverScaleCount,
          };
          const item4 = {
            name: "未绑定设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.unBindScaleCount,
          };
          const item5 = {
            name: "未激活设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.idleScaleCount,
          };
          this.deviceData.push(item, item1, item2, item3, item4, item5);
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },
  },

  mounted() {
    this.listMarkets(1, 10);
    this.countDevices("");
    this.listSellerCheckScale("", 1, 10);
    this.listSellerScaleJoinView("", 1, 10);
    this.setEachrts2Option("month");
  },
};
</script>
  <style lang="less" scoped>
.user {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  img {
    margin-right: 40px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .userinfo {
    .name {
      font-size: 32px;
      margin-bottom: 10px;
    }
    .access {
      color: #999999;
    }
  }
}
.login-info {
  p {
    line-height: 28px;
    font-size: 14px;
    color: #999999;
    span {
      color: #666666;
      margin-left: 60px;
    }
  }
}

.device {
  height: 80px;
  width: 30%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1%;
  .icon {
    width: 80px;
    height: 80px;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    color: #fff;
  }
  .detail {
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .desc {
      height: 40px;
      font-size: 28px;
      color: #000;
      font-weight: bold;
    }
  }
  .detail1 {
    display: flex;
    flex-direction: row;
    .desc {
      font-size: 14px;
      color: #999;
      font-weight: normal;
    }
  }
}

.num {
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1%;
  .icon {
    width: 100px;
    height: 100px;
    font-size: 30px;
    text-align: center;
    line-height: 100px;
    color: #fff;
  }
  .detail {
    margin-left: 15px;
    margin-top: 1%;
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .price {
      font-size: 24px;
      margin-top: 1%;
      line-height: 30px;
      font-weight: bold;
      height: 30px;
    }
    .desc {
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
  }
  .detail1 {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    .desc {
      font-size: 14px;
      color: #535252;
    }
  }
  .el-card {
    width: 24%;
    margin-bottom: 20px;
  }
}
.graph {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
}
.graph1 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.manage {
  height: 100%;
  overflow: hidden;
  .el-dialog__title {
    color: brown;
  }
  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .common-tabel {
    position: relative;
    height: calc(100% - 62px);
    overflow: auto;
    .pager {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>