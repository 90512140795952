import http from "@/utils/http.js";
import qs from "qs";

export default {
    adminLogin,
    updateRootManagerPasswordBySelf,
    getMarketManagerByPage,
    getMarketMonitorManagerByPage,
    updateMarketMonitorManagerInfo,
    updateMarketManagerInfo,
    deleteMarketManagerInfo,
    createMarketManagerAccount,
    createMarketMonitorManagerAccount,
    deleteMarketMonitorManagerInfo,
    listShopCount,

    loginMarketManager,
    listMarkets,
    getMarketsAccount,
    getAllOrderCount,
    getAllOrderCountGroupByDate,
    countDevices,
    countEquitableRecord,

    listSellerScaleJoinView,
    getSolidicSellers,


    loginMarketMonitorManager,
    listSellerCheckScale,
    listAbnormalScaleMessageByPage,
    setAbnormalScale,
    handleAbnormalDeviceMessage,
    getBindedMarketListForMarketMonitor,
    getMarketListForMarketMonitor,
    updateScaleCheckDate,
    listEquitableRecordByMonth,
    listEquitableRecordByYear,
    monitorUnbindMarket,
    monitorBindMarket,
    
}
/**管理员登录 */
export function adminLogin(params) {
    console.dir("管理员登录\n", params)
    return http.post("/api" + "/loginRootMarketManager", params);
}
/**超级管理员分页获取市场管理员信息 */
export function getMarketManagerByPage(params) {
    console.dir("分页获取市场管理员信息\n", params)
    return http.signPost("/api" + "/marketService/getMarketManagerByPage", params);
}
/**超级管理修改密码 */
export function updateRootManagerPasswordBySelf(params) {
    console.dir("超级管理修改密码\n", params)
    return http.signPost("/api" + "/marketService/updateRootManagerPasswordBySelf", params);
}


/**超级管理员创建管理员账号 */
export function createMarketManagerAccount(params) {
    console.dir("分页获取市场监督局信息\n", params)
    return http.signPost("/api" + "/marketService/createMarketManagerAccount", params);
}

/**超级管理员更新市场管理员信息/重置管理员密码 */
export function updateMarketManagerInfo(params) {
    console.dir("超级管理员更新市场管理员信息\n", params)
    return http.signPost("/api" + "/marketService/updateMarketManagerInfo", params);
}

/**超级管理员删除市场管理账号 */
export function deleteMarketManagerInfo(params) {
    console.dir("分页获取市场监督局信息\n", params)
    return http.signPost("/api" + "/marketService/deleteMarketManagerInfo", params);
}


/**超级管理员分页获取市场监督局信息 */
export function getMarketMonitorManagerByPage(params) {
    console.dir("分页获取市场监督局信息\n", params)
    return http.signPost("/api" + "/marketService/getMarketMonitorManagerByPage", params);
}


/**超级管理员更新市场监督局信息/重置监督局密码 */
export function updateMarketMonitorManagerInfo(params) {
    console.dir("分页获取市场监督局信息\n", params)
    return http.signPost("/api" + "/marketService/updateMarketMonitorManagerInfo", params);
}

/**超级管理员创建监管员账号 */
export function createMarketMonitorManagerAccount(params) {
    console.dir("分页获取市场监督局信息\n", params)
    return http.signPost("/api" + "/marketService/createMarketMonitorManagerAccount", params);
}

/**超级管理员删除市场监督局账号 */
export function deleteMarketMonitorManagerInfo(params) {
    console.dir("刪除\n", params)
    return http.signPost("/api" + "/marketService/deleteMarketMonitorManagerInfo", params);
}


/**市场管理员登录 */
export function loginMarketManager(params) {
    console.dir("市场管理员登录\n", params)
    return http.post("/api" + "/loginMarketManager", params);
}
/**市场管理员分页获取市场列表 */
export function listMarkets(params) {
    console.dir("市场管理员分页获取市场列表\n", params)
    return http.signPost("/api" + "/marketService/listMarkets", params);
}
/**市场管理员分页获取市场商户 */
export function getSolidicSellers(params) {
    console.dir("市场管理员分页获取市场商户\n", params)
    return http.signPost("/api" + "/marketService/getSolidicSellers", params);
}


/**看板订单统计 */
export function getAllOrderCount(params) {
    console.dir("看板订单统计\n", params)
    return http.signPost("/api" + "/marketService/getAllOrderCount", params);
}
/**市场管理员获取市场统计信息 */
export function getMarketsAccount(params) {
    console.dir("十，市场管理员获取市场统计信息\n", params)
    return http.signPost("/api" + "/marketService/getMarketsAccount", params);
}

/**销售分布 */
export function listShopCount(params) {
    console.dir("销售分布\n", params)
    return http.signPost("/api" + "/marketService/listShopCount", params);
}



/**按日期分组订单统计 */
export function getAllOrderCountGroupByDate(params) {
    console.dir("按日期分组订单统计\n", params)
    return http.signPost("/api" + "/marketService/getAllOrderCountGroupByDate", params);
}

/**设备统计 */
export function countDevices(params) {
    console.dir("设备统计\n", params)
    return http.signPost("/api" + "/marketService/countDevices", params);
}
/**称重统计 */
export function countEquitableRecord(params) {
    console.dir("称重统计\n", params)
    return http.signPost("/api" + "/marketService/countEquitableRecord", params);
}

/**获取设备列表视图 */
export function listSellerScaleJoinView(params) {
    console.dir("获取设备列表视图:\n", params)
    return http.signPost("/api" + "/marketService/listSellerScaleJoinView", params);
}




/**市场监管员登录 */
export function loginMarketMonitorManager(params) {
    console.dir("市场监管员登录\n", params)
    return http.post("/api" + "/loginMarketMonitorManager", params);
}

/**市场设备 */
export function listSellerCheckScale(params) {
    console.dir("市场设备\n", params)
    return http.signPost("/api" + "/marketService/listSellerCheckScale", params);
}
/**获取设备异常记录 */
export function listAbnormalScaleMessageByPage(params) {
    console.dir("获取设备异常记录\n", params)
    return http.signPost("/api" + "/marketService/listAbnormalScaleMessageByPage", params);
}
/**记录原因 */
export function setAbnormalScale(params) {
    console.dir("记录原因\n", params)
    return http.signPost("/api" + "/marketService/setAbnormalScale", params);
}

/**清除异常 */
export function handleAbnormalDeviceMessage(params) {
    console.dir("清除原因异常\n", params)
    return http.signPost("/api" + "/marketService/handleAbnormalDeviceMessage", params);
}

/**监管获取管理市场 */
export function getBindedMarketListForMarketMonitor(params) {
    console.dir("清除原因异常\n", params)
    return http.signPost("/api" + "/marketService/getBindedMarketListForMarketMonitor", params);
}
/**监管获取管理市场 */
export function getMarketListForMarketMonitor(params) {
    console.dir("清除原因异常\n", params)
    return http.signPost("/api" + "/marketService/getMarketListForMarketMonitor", params);
}




/**监管更新年检时间 */
export function updateScaleCheckDate(params) {
    console.dir("年检时间\n", params)
    return http.signPostSet("/api" + "/marketService/updateScaleCheckDate", params);
}

/**监管图表本月 */
export function listEquitableRecordByMonth(params) {
    console.dir("监管图表本月\n", params)
    return http.signPost("/api" + "/marketService/listEquitableRecordByMonth", params);
}
/**监管图表本年 */
export function listEquitableRecordByYear(params) {
    console.dir("监管图表本年\n", params)
    return http.signPost("/api" + "/marketService/listEquitableRecordByYear", params);
}

/**解除监控市场 */
export function monitorUnbindMarket(params) {
    console.dir("监管市场解除监控市场\n", params)
    return http.signPost("/api" + "/marketService/monitorUnbindMarket", params);
}
/**绑定监控市场 */
export function monitorBindMarket(params) {
    console.dir("监管市场绑定监控市场\n", params)
    return http.signPost("/api" + "/marketService/monitorBindMarket", params);
}




//退出
export function authLogout(params) {
    return request({
        url: baseUrl + "/logout",
        method: "get",
    });
}
//获取用户数据
export function getUserInfo(params) {
    return request({
        url: baseUrl + "/getUserInfo",
        method: "get",
        params: qs.stringfy(params)
    });
}

//其实，也不一定就是params，也可以是 query 还有 data 的呀！
//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。