/****   http.js   ****/
// 导入封装好的axios实例
import request from './request'
import request3 from './request3'
import m5 from './m5'
import Cookie from "js-cookie";
import qs from 'qs'
import axios from 'axios'

/**普通post请求，使用formdata方式 */
export function post(url, params) {
  console.dir("ddd")
  const config = {
    method: 'post',
    url: url,
    data: {
      json: JSON.stringify(params)
    },
    transformRequest: [
      function (data) {
        // Do whatever you want to transform the data
        let ret = "";
        for (let it in data) {
          ret +=
            encodeURIComponent(it) +
            "=" +
            encodeURIComponent(data[it]) +
            "&";
        }
        return ret;
      },
    ],
  }
  console.dir(config)
  return request.service(config)
}

/**post请求，使用formdata方式，添加sign 数组传值*/
export function signPostSet(url, params1) {
  var devices = params1.devices
  let params = json_sort(params1)
  console.dir("结果params1", params1)
  var sign128 = Cookie.get('key')
  // var sign128 = "qlgu9Nvyd2fTUBLN90I609j8I11eMLUbIkpbIFK8cVsvqv8MISK3I9nPxu0M0k9vZJvqJK63GHMmfCPXhIrsH2wtcSQzrtOy9Rz3qULvDZHQqsquGlxoIkb2bsqusaSa"
  if (sign128 == null || sign128.length != 128) {
    return null;
  }
  var sign32 = sign128.substring(10, 12) + sign128.substring(5, 13) + sign128.substring(64, 86);
  var entry = objToStrMap(params);
  var jsonStr = ''
  entry.forEach(function (value, key) {
    console.dir("结果key", key)
    if (jsonStr == "") {
      if(key === "devices"){
        jsonStr = key + "=" + JSON.stringify(devices)
      }else{
        jsonStr = key + "=" + value
      }
    } else {
      if(key === "devices"){
        jsonStr = jsonStr + "&" + key + "=" + JSON.stringify(devices)
      }else{
        jsonStr = jsonStr + "&" + key + "=" + value
        // jsonStr = 'devices=[{"scaleMac":"456787879809009"}]&scaleCheckDate=2024-05-01&scaleNextCheckDate=2024-05-10&token=a144fd602fedde7c582c4539864cc414b2d15ff0&key=fTNvyd2fTUZJvqJK63GHMmfCPXhIrsH2'
      }
    }
  });
  jsonStr = jsonStr + "&key=" + sign32
  // jsonStr = 'devices=[{"scaleMac":"456787879809009"}]&scaleCheckDate=2024-05-01&scaleNextCheckDate=2024-05-10&token=&key=fTNvyd2fTUZJvqJK63GHMmfCPXhIrsH2'

  console.dir("结果最终",  jsonStr)
  var json = m5(jsonStr);
  // 创建FormData对象
let formData = new FormData();



let data = {
  devices: devices,
  scaleCheckDate:params1.scaleCheckDate,
  scaleNextCheckDate:params1.scaleNextCheckDate,
  token:params1.token,
  // token:"a144fd602fedde7c582c4539864cc414b2d15ff0",
  sign:json,
}
formData.append('json', JSON.stringify(data));


for (var value of formData.values()) {
  console.log("结果formadata",value);
}
var parm = JSON.stringify(data);
  // console.dir("结果", parm)
  const config = {
    method: 'post',
    url: url,
    data: {
      json: parm
    },
  }
  return request3.service(config)
}

/**post请求，使用formdata方式，添加sign */
export function signPost(url, params1) {
  let params = json_sort(params1)
  var sign128 = Cookie.get('key')
  // console.dir("sign128", sign128)
  if (sign128 == null || sign128.length != 128) {
    return null;
  }
  var sign32 = sign128.substring(10, 12) + sign128.substring(5, 13) + sign128.substring(64, 86);
  var entry = objToStrMap(params);
  var jsonStr = ''
  entry.forEach(function (value, key) {
    if (jsonStr == "") {
      jsonStr = key + "=" + value
    } else {
      jsonStr = jsonStr + "&" + key + "=" + value
    }
  });
  jsonStr = jsonStr + "&key=" + sign32
  var json = m5(jsonStr);
  entry.set("sign", json)
  let obj = Object.create(null);
  for (let [k, v] of entry) {
    obj[k] = v;
  }
  var parm = JSON.stringify(obj);
  // console.dir("结果", parm)
  const config = {
    method: 'post',
    url: url,
    data: {
      json: parm
    },
    transformRequest: [
      function (data) {
        // Do whatever you want to transform the data
        let ret = "";
        for (let it in data) {
          ret +=
            encodeURIComponent(it) +
            "=" +
            encodeURIComponent(data[it]) +
            "&";
        }
        return ret;
      },
    ],
  }
  // console.dir("结果", config)
  return request.service(config)
}
/**
*对象转换为Map
*/
export function objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
      strMap.set(k, obj[k]);
  }
  return strMap;
}
// json按key值排序
export function json_sort(data) {
  data = data || '{}';
  let arr = [];
  for (var key in data) {
    arr.push(key)
  }
  arr.sort();
  let str = '{';
  for (var i in arr) {
    str += '"' + arr[i] + '":"' + data[arr[i]] + '",'
  }
  str = str.substr(0, str.length - 1)
  str += '}'
  console.log("str=" + str)
  str = JSON.parse(str);
  return str;
}



//导出
export default {
  post,
  signPost,
  signPostSet,
}