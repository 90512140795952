<template>
  <el-row>
    <el-col>
      <div class="graph">
        <el-card
          style="height: auto; width: 16%; margin-right: 0.8%"
          :body-style="{ padding: '0px' }"
        >
          <div
            class="el-icon-arrow-left"
            style="
              width: 100%;
              line-height: 60px;
              text-align: center;
              font-weight: bold;
            "
            @click="toBack"
          >
            返回上一级
          </div>
          <h1
            style="width: 100%; height: 1px; background: #ededed; margin: -0px"
          ></h1>
          <div class="common-tabel">
            <el-table
              :data="tableData"
              height="620px"
              :show-header="false"
              highlight-current-row
              @row-click="getShop"
              :row-class-name="tableRowClassName">
            >
              <el-table-column
                prop="marketName"
                label="市场名称"
                align="center"
              >
              </el-table-column>
            </el-table>
            <div class="pager">
              <!-- <el-pagination
              style="margin-top: 20px"
              layout="->,prev, pager, next"
              :total="total"
              @current-change="handlePage"
            >
            </el-pagination> -->
              <!--分页按钮-->
              <el-pagination
                background
                layout="prev, pager, next,jumper"
                :total="total"
                @current-change="handlePage"
                :current-page="pageData.page"
                :page-size="pageData.size"
                style="text-align: center"
              >
              </el-pagination>
            </div>
          </div>
        </el-card>
        <div class="query" style="height: auto; width: 82.4%">
          <el-card>
            <el-form
              :inline="true"
              :model="checkFrom"
              class="demo-form-inline"
            >
              <el-form-item label="商户名称：">
                <el-input
                  v-model="nickName"
                  clearable
                  placeholder="请输入商户名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="店铺名称：" style="margin-left: 5px">
                <el-input
                  v-model="shopName"
                  clearable
                  placeholder="请输入店铺名称"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  style="
                    background: #ffffff;
                    color: #000;
                    border-color: #dddddd;
                  "
                  @click="onRest"
                  >重置</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onCheck">查询</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card>
            <div class="manage">
              <el-dialog
                :visible.sync="dialogVisible"
                width="40%"
                :before-close="handleClose"
              >
                <div
                  slot="title"
                  class="header-title"
                  :style="{
                    background: 'white',
                    color: 'black',
                    'font-weight': 'bold',
                  }"
                >
                  <div
                    style="
                      border-left: 4px solid rgb(54, 161, 233);
                      width: 90px;
                      font-weight: bold;
                      text-align: center;
                    "
                  >
                    {{ dialogTitle }}
                  </div>
                </div>

                <!-- 用户的表单信息 -->
                <el-form
                  ref="form"
                  :rules="rules"
                  :model="form"
                  label-width="160px"
                >
                  <el-form-item label="市场管理名称：" prop="marketName">
                    <el-input
                      placeholder="请输入市场管理名称"
                      v-model="form.marketName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="姓名：" prop="managerName">
                    <el-input
                      placeholder="请输入姓名"
                      v-model="form.managerName"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="联系电话：" prop="managerLinkNo">
                    <el-input
                      placeholder="请输入联系电话"
                      v-model="form.managerLinkNo"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="登录账号：" prop="loginName">
                    <el-input
                      placeholder="请输入登录账号"
                      v-model="form.loginName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="登录密码：" prop="loginPassword">
                    <el-input
                      placeholder="admin123456"
                      :disabled="true"
                      v-model="admin123456"
                      value="admin123456"
                    ></el-input>
                  </el-form-item>
                </el-form>

                <span slot="footer" class="dialog-footer">
                  <el-button @click="cancel">取 消</el-button>
                  <el-button type="primary" @click="submit">确 定</el-button>
                </span>
              </el-dialog>
              <div class="manage-header">
                <el-button @click="handleAdd" type="primary">
                  + 新增
                </el-button>
              </div>
            </div>

            <div class="common-tabel">
              <el-table :data="shopData" height="620px">
                <el-table-column prop="nickname" label="商户姓名">
                </el-table-column>
                <el-table-column prop="phoneNo" label="联系电话">
                </el-table-column>
                <el-table-column prop="shopName" label="店铺名称">
                </el-table-column>
                <el-table-column prop="shopType" label="商品类别">
                </el-table-column>
                <el-table-column prop="shop" label="店铺详细地址">
                  <template slot-scope="scope">
                    <p>{{ address }} {{ scope.row.shopAddress }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="addr" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      size="mini"
                      @click="handleDelete(scope.row)"
                      >刪除</el-button
                    >

                    <el-button size="mini" @click="handleEdit(scope.row)"
                      >编辑</el-button
                    >
                    <el-button size="mini" @click="toshop(scope.row)"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <div class="pager">
                <!-- <el-pagination
              style="margin-top: 20px"
              layout="->,prev, pager, next"
              :total="total"
              @current-change="handlePage"
            >
            </el-pagination> -->
                <!--分页按钮-->
                <el-pagination
                  background
                  layout="prev, pager, next,jumper"
                  :total="total"
                  @current-change="handlePage"
                  :current-page="pageData.page"
                  :page-size="pageData.size"
                  style="text-align: center"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
      <script>
import { getUser, addUser, editUser, delUser } from "../../api";
import { regionData, codeToText } from "element-china-area-data";
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不可为空"));
      } else {
        if (value !== "") {
          var reg = /^1[3-9]\d{9}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的手机号码"));
          }
        }
        callback();
      }
    };

    return {
      address: "",
      regionData,
      codeToText,
      marketName: "",
      dialogVisible: false,
      form: {
        loginName: "",
        loginPassword: "admin123456",
        managerGroupName: "",
        managerLinkNo: "",
        managerName: "",
        rootMarketManagerId: "",
      },
      editForm: {
        loginName: "",
        loginPassword: "admin123456",
        managerGroupName: "",
        managerLinkNo: "",
        managerName: "",
        rootMarketManagerId: "",
      },
      rules: {
        managerGroupName: [{ required: true, message: "请输入市场管理名称" }],
        managerName: [{ required: true, message: "请输入姓名" }],
        managerLinkNo: [
          { required: true, message: "请输入联系电话" },
          {
            validator: checkMobile,
            min: 11,
            max: 11,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        loginName: [{ required: true, message: "请输入登录账号" }],
      },
      tableData: [],
      shopData: [],
      modalType: 0, // 0表示新增的弹窗， 1表示编辑
      dialogTitle: "",
      total: 0, //当前的总条数
      pageData: {
        page: 1,
        limit: 10,
      },
      userForm: {
        name: "",
      },
    };
  },
  methods: {

    /**
     * 获取市场管理员列表数据
     */
    getList(page) {
      var data = {
        token: this.$cookie.get("token"),
        marketName: "",
        page: page,
        size: 10,
      };
      this.$api.listMarkets(data).then((res) => {
        console.dir("返回数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.tableData = res.data.dataObject.data;
          this.total = res.data.dataObject.pageTotal * this.pageData.limit;
        } else {
          this.$message.error(res.data.message);
        }
      });

      var data = {
        token: this.$cookie.get("token"),
        marketId: 54,
        page: 1,
        size: 10,
      };
      this.$api.getSolidicSellers(data).then((res) => {
        console.dir("返回数据1：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.shopData = res.data.dataObject.data;
          console.dir("返回数据1：", this.shopData);
          // this.total = res.data.dataObject.pageTotal * this.pageData.limit;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    tableRowClassName({row, rowIndex}) {
     if (rowIndex === 1) {
       return 'warning-row';
     } else if (rowIndex === 3) {
       return 'success-row';
     }
     return '';
   },


    // 选择页码的回调函数
    handlePage(val) {
      this.pageData.page = val;
      console.dir("返回数据：", val);
      this.getList(val);
    },

    toBack() {
      this.$router.go(-1);
    },

    /** 重置搜索选项*/
    onRest() {
      this.marketName = "";
      this.getList(1);
    },
    /** 列表模糊查询*/
    onCheck() {
      var data = {
        token: this.$cookie.get("token"),
        marketName: this.marketName,
        page: 1,
        size: 10,
      };
      this.$api.listMarkets(data).then((res) => {
        console.dir("返回数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.tableData = res.data.dataObject.data;
          this.total = res.data.dataObject.pageTotal * this.pageData.limit;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    /**
     * 商户列表
     */
    toshop(row) {
      this.$router.push({ name: "Marketedit" });
    },

    getShop(row, event, column) {
      this.shopData= [];
      this.address =[];
      this.address =
        codeToText[row.provinceCode.slice(0, 2)] +
        codeToText[row.cityCode.slice(0, 4)] +
        codeToText[row.countryCode];
      var data = {
        token: this.$cookie.get("token"),
        marketId: row.id,
        page: 1,
        size: 10,
      };
      this.$api.getSolidicSellers(data).then((res) => {
        console.dir("返回数据1：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.shopData = res.data.dataObject.data;
          console.dir("返回数据1：", this.shopData);
          // this.total = res.data.dataObject.pageTotal * this.pageData.limit;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    /**新增管理员 */
    handleAdd() {
      this.form = {
        loginName: "",
        loginPassword: "",
        managerGroupName: "",
        managerLinkNo: "",
        managerName: "",
        rootMarketManagerId: "",
      };
      this.modalType = 0;
      this.dialogTitle = "新增用户";
      this.dialogVisible = true;
    },
    /**编辑管理员 */
    handleEdit(row) {
      // 注意需要对当前行数据进行深拷贝，否则会出错
      this.form = JSON.parse(JSON.stringify(row));
      this.editForm = JSON.parse(JSON.stringify(row));
      this.modalType = 1;
      this.dialogTitle = "编辑用户";
      this.dialogVisible = true;
    },

    // 提交用户表单
    submit() {
      this.$refs.form.validate((valid) => {
        // console.log(valid, 'valid')
        if (valid) {
          // 后续对表单数据的处理
          if (this.modalType === 0) {
            // 新增管理
            var data = {
              token: this.$cookie.get("token"),
              loginPassword: "admin123456",
              loginName: this.form.loginName,
              managerName: this.form.managerName,
              managerLinkNo: this.form.managerLinkNo,
              managerGroupName: this.form.managerGroupName,
            };
            this.$api.createMarketManagerAccount(data).then((res) => {
              console.dir("返回数据：", res);
              if (res.status === 200 && res.data.codeId === 1) {
                this.$message({
                  type: "success",
                  message: "新增管理员信息成功!",
                });
                this.getList(this.pageData.page);
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            // 编辑
            const data = {
              token: this.$cookie.get("token"),
              marketManagerId: this.form.marketManagerId,
              loginPassword: "admin123456",
              loginName: this.form.loginName,
              managerName: this.form.managerName,
              managerLinkNo: this.form.managerLinkNo,
              managerGroupName: this.form.managerGroupName,
            };

            if (this.form.loginName == this.editForm.loginName) {
              this.$delete(data, "loginName");
            }

            this.$api.updateMarketManagerInfo(data).then((res) => {
              console.dir("返回数据：", res);
              if (res.status === 200 && res.data.codeId === 1) {
                this.$message({
                  type: "success",
                  message: "更新管理员信息成功!",
                });
                this.getList(this.pageData.page);
                this.$refs.form.resetFields();
              } else {
                this.$message.error(res.data.message);
              }
            });
          }

          // 清空表单的数据
          this.$refs.form.resetFields();
          // 关闭弹窗
          this.dialogVisible = false;
        }
      });
    },

    handleDelete(row) {
      this.$confirm("确定要删除该用户么", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.dir("1", row);

          var data = {
            token: this.$cookie.get("token"),
            marketManagerId: row.marketManagerId,
          };
          this.$api.deleteMarketManagerInfo(data).then((res) => {
            console.dir("返回数据：", res);
            if (res.status === 200 && res.data.codeId === 1) {
              this.$message({
                type: "success",
                message: "删除用户成功",
              });
              this.$refs.form.resetFields();
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 弹窗关闭的时候
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    cancel() {
      this.$refs.form.resetFields();
      this.handleClose();
    },
  },
  mounted() {
    this.getList(1);
  },
};
</script>
      <style lang="less" scoped>
.manage {
  height: 90%;
  .el-dialog__title {
    color: brown;
  }
  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .common-tabel {
    position: relative;
    height: calc(100% - 62px);
    overflow: auto;
    .pager {
      position: absolute;
      bottom: 0;
    }
  }
}

.graph {
  display: flex;
  justify-content: space-between;
}
.graph1 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
      