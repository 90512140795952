import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Main from '../views/Main.vue'
import Mall from '../views/Mall.vue'
import SystemManagement from '../views/SystemManagement/SystemManagement.vue'
import Administrator from '../views/SystemManagement/Administrator.vue'
import MarketData from '../views/MarketManagement/MarketData.vue'
import Marketedit from '../views/MarketManagement/Marketedit.vue'
import MarketSurveillanceEdit from '../views/MarketSurveillance/MarketSurveillanceEdit.vue'
import marketSurveillance from '../views/MarketSurveillance/marketSurveillance.vue'
import PageOne from '../views/PageOne.vue'
import PageTwo from '../views/PageTwo.vue'
import Login from '../views/Login.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
// 1. 创建路由组件
// 2. 将路由与组件进行映射
// 3. 创建router实例

const routes = [
    // 主路由
    {
        path: '/',
        component: Main,
        name: 'Main',
        redirect: '/login', // 重定向
        children: [
            // 子路由
            { path: 'admininistrator', name: 'admininistrator', component: Administrator }, // 监督员
            { path: 'MarketData', name: 'MarketData', component: MarketData }, // 监督员
            { path: 'Marketedit', name: 'Marketedit', component: Marketedit }, // 监督员
            { path: 'MarketSurveillanceEdit', name: 'MarketSurveillanceEdit', component: MarketSurveillanceEdit }, // 监督员
            { path: 'marketSurveillance', name: 'marketSurveillance', component: marketSurveillance }, // 监督员
            { path: 'home', name: 'home', component: Home }, // 首页
            { path: 'user', name: 'user', component: User }, // 用户管理
            { path: 'mall', name: 'mall', component: Mall }, // 商品管理
            { path: 'page1', name: 'page1', component: PageOne }, // 页面1
            { path: 'page2', name: 'page2', component: PageTwo }, // 页面2
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/admininistrator',
        name: 'admininistrator',
        component: Administrator
    },
    {
        path: '/marketSurveillance',
        name: 'marketSurveillance',
        component: marketSurveillance
    },
    {
        path: '/MarketData',
        name: 'MarketData',
        component: MarketData
    },
    {
        path: '/Marketedit',
        name: 'Marketedit',
        component: Marketedit
    },
    {
        path: '/MarketSurveillanceEdit',
        name: 'MarketSurveillanceEdit',
        component: MarketSurveillanceEdit
    },
]

const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
})

export default router