
<template>
  <el-row style="overflow: scroll; height: 100vh; padding-bottom: 100px">
    <el-col style="padding-right: 10px">
      <!-- <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <el-select
        v-model="value"
        placeholder="全部"
        clearable
        filterable
        @blur="selectBlur"
        @clear="selectClear"
        @change="selectChange"
        v-loadmore="loadMore"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.label"
          :value="item.marketName"
        />
      </el-select>

      <div class="num">
        <el-card
          v-for="item in countData"
          :key="item.name"
          style="
            height: 120px;
            display: flex;
            justify-content: space-between;
            justify-items: center;
            align-items: center;
          "
          :body-style="{ display: 'flex', padding: 0 }"
        >
          <div class="detail" style="width: 280px">
            <p class="desc">{{ item.name }}</p>
            <p class="price">￥{{ item.value }}</p>
            <div class="detail1">
              <p class="desc">{{ item.text }}</p>
              <p class="desc" style="margin-left: 10px">{{ item.data }}</p>
              <p class="desc">!</p>
            </div>
          </div>
          <i
            class="icon"
            :class="`el-icon-${item.icon}`"
            :style="{ background: item.color }"
          ></i>
        </el-card>
      </div>
    </el-col>
    <el-col>
      <div class="graph">
        <el-card style="height: 300px; width: 49%">
          <div ref="echarts2" style="height: 300px; margin: -1%"></div>
        </el-card>
        <div class="graph1">
          <el-card
            style="height: 300px; width: 48%; margin-right: 1%"
            :body-style="{ padding: '10px' }"
          >
            <div ref="echarts3" style="height: 280px; background: #fff"></div>
          </el-card>
          <el-card
            style="height: 300px; width: 48%; margin-left: 1%"
            :body-style="{ padding: '0px' }"
          >
            <div ref="echarts1" style="height: 300px"></div>
          </el-card>
        </div>
      </div>
    </el-col>

    <el-col>
      <div class="graph">
        <el-card style="height: 140px; width: 74.4%; margin-right: 1.1%">
          <div style="color: #000; font-weight: bold">设备运行情况</div>
          <div
            style="
              height: 100px;
              width: 100%;
              margin-top: 5px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              class="device"
              v-for="item in deviceData"
              :key="item.name"
              :body-style="{ display: 'flex', padding: 0 }"
            >
              <i
                class="icon"
                :class="`el-icon-${item.icon}`"
                :style="{ background: item.color }"
              ></i>
              <div class="detail">
                <p class="desc">{{ item.value }}</p>
                <div class="detail1">
                  <p class="desc">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card style="height: 140px; width: 24%; margin-right: 5px">
          <div style="color: #000; font-weight: bold">公平秤数据</div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 100px;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: center;
                width: 50%;
              "
            >
              <div
                style="
                  height: 40px;
                  font-size: 22px;
                  color: #000;
                  font-weight: bold;
                "
              >
                {{ dayCount }}
              </div>
              <div style="font-size: 14px; color: #999; font-weight: normal">
                今日称重(次)
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: center;
                width: 50%;
              "
            >
              <div
                style="
                  height: 40px;
                  font-size: 22px;
                  color: #000;
                  font-weight: bold;
                "
              >
                {{ yearMonthCount }}
              </div>
              <div style="font-size: 14px; color: #999; font-weight: normal">
                本月称重(次)
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </el-col>
    <el-col>
      <div class="graph">
        <el-card style="height: 300px; width: 49.3%">
          <div style="color: #000; font-weight: bold">年检设备</div>
          <el-table :data="tableData" style="width: 100%">
            <!-- <el-table-column
                        prop="name"
                        label="课程">
                    </el-table-column>
                    <el-table-column
                        prop="todayBuy"
                        label="今日购买">
                    </el-table-column>
                    <el-table-column
                        prop="monthBuy"
                        label="本月购买">
                    </el-table-column>
                    <el-table-column
                        prop="totalBuy"
                        label="总购买">
                    </el-table-column> -->
            <el-table-column
              v-for="(val, key) in tableLabel"
              :key="key"
              :prop="key"
              :label="val"
            />
          </el-table>
        </el-card>
        <el-card style="height: 300px; width: 49.3%; margin-right: 5px">
          <div style="color: #000; font-weight: bold">异常设备</div>
          <el-table :data="tableData" style="width: 100%">
            <!-- <el-table-column
                        prop="name"
                        label="课程">
                    </el-table-column>
                    <el-table-column
                        prop="todayBuy"
                        label="今日购买">
                    </el-table-column>
                    <el-table-column
                        prop="monthBuy"
                        label="本月购买">
                    </el-table-column>
                    <el-table-column
                        prop="totalBuy"
                        label="总购买">
                    </el-table-column> -->
            <el-table-column
              v-for="(val, key) in tableLabel1"
              :key="key"
              :prop="key"
              :label="val"
            />
          </el-table>
        </el-card>
      </div>
    </el-col>
  </el-row>
</template>
  <script>
import { getData } from "../../api";
import * as echarts from "echarts";
export default {
  data() {
    return {
      marketId: "",

      options: [],
      tableData: [],
      tableLabel: {
        name: "设备",
        market: "所属市场",
        shop: "所属店铺",
        phone: "联系电话",
        code: "年检信息",
        mark: "操作",
      },
      tableLabel1: {
        name: "设备",
        market: "所属市场",
        shop: "所属店铺",
        phone: "联系电话",
        code: "异常信息",
        mark: "操作",
      },
      countData: [],
      deviceData: [],
      countPriceGroupByItemMappings: [], //条形图
      sumPriceGroupByItemMappings: [], //圆形图
      dayCount: "",
      yearMonthCount: "",
    };
  },

  methods: {
    loadMore() {
      // 在这里请求接口加载数据
      console.log("滚动到底部了");
    },
    selectBlur(e) {
      // 意见类型
      if (e.target.value !== "") {
        this.value = e.target.value + "(其他)";
        this.$forceUpdate(); // 强制更新
      }
    },
    selectClear() {
      this.value = "";
      this.marketId = "";
      this.getMarketsAccount();
      this.$forceUpdate();
    },
    selectChange(val) {
      this.value = val;
      let result = [];
      if (val === "全部") {
        this.marketId = "";
      } else {
        for (let item of this.options) {
          if (item.marketName === val) {
            console.log("市场选中val", val);
            console.log("市场选中marketName", item.marketName);
            result.push(item);
            console.log("市场选中result", result);
          }
        }
        console.log("市场选中marketId", result[0].id);
        if (result[0].id != undefined) {
          this.marketId = result[0].id;
        }
      }

      this.$forceUpdate();

      this.getMarketsAccount();
    },

    listMarkets(page, size) {
      var data = {
        token: this.$cookie.get("token"),
        page: page,
        size: size,
      };
      this.$api.listMarkets(data).then((res) => {
        console.dir("返回数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.options = res.data.dataObject.data;
          this.options.unshift({ marketName: "全部" });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    getMarketsAccount() {
      const data = {
        token: this.$cookie.get("token"),
        marketId: this.marketId,
      };
      //   if (true) {
      //     data = {

      //     };
      //   } else {
      //     data = {
      //       token: this.$cookie.get("token"),
      //       marketId: "",
      //     };
      //   }

      this.$api.getAllOrderCount(data).then((res) => {
        console.dir("统计数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.countData = [];
          const item = {
            name: "今日交易金额（元）",
            value: this.regFenToYuan(res.data.dataObject.orderPriceToday),
            text: "相比昨日",
            icon: "date",
            icon1: "date",
            color: "#2ec7c9",
            data: this.getCode(
              (res.data.dataObject.orderPriceToday -
                res.data.dataObject.orderCountYesterday) /
                res.data.dataObject.orderCountYesterday
            ),
          };
          const item1 = {
            name: "今日交易笔数（笔）",
            value: res.data.dataObject.orderCountToday,
            text: "相比昨日",
            icon: "document-copy",
            icon1: "document-copy",
            color: "#2ec7c9",
            data: this.getCode(
              (res.data.dataObject.orderCountToday -
                res.data.dataObject.orderCountYesterday) /
                res.data.dataObject.orderCountYesterday
            ),
          };
          const item2 = {
            name: "本月交易金额（元）",
            value: this.regFenToYuan(
              res.data.dataObject.orderPriceCurrentMonth
            ),
            text: "环比",
            icon: "tickets",
            icon1: "tickets",
            color: "#2ec7c9",
            data: this.getCode(
              (res.data.dataObject.orderPriceCurrentMonth -
                res.data.dataObject.orderPriceLastMonth) /
                res.data.dataObject.orderPriceLastMonth
            ),
          };
          const item3 = {
            name: "本月交易笔数（笔）",
            value: res.data.dataObject.orderCountCurrentMonth,
            text: "环比",
            icon: "document",
            icon1: "document",
            color: "#2ec7c9",
            data: this.getCode(
              (res.data.dataObject.orderCountCurrentMonth -
                res.data.dataObject.orderCountLastMonth) /
                res.data.dataObject.orderCountLastMonth
            ),
          };
          this.countData.push(item, item1, item2, item3);
          console.dir(this.countData);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    countDevices() {
      const data = { token: this.$cookie.get("token") };
      //   if (true) {
      //     data = {

      //     };
      //   } else {
      //     data = {
      //       token: this.$cookie.get("token"),
      //       marketId: "",
      //     };
      //   }

      this.$api.countDevices(data).then((res) => {
        console.dir("统计数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          const item = {
            name: "设备总数（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.scaleCount,
          };
          const item1 = {
            name: "正常运行设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.normalScaleCount,
          };
          const item2 = {
            name: "异常运行设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.abnormalScaleCount,
          };
          const item3 = {
            name: "年检超期设备（台）",
            icon: "success",
            icon1: "success",
            color: "#2ec7c9",
            value: res.data.dataObject.checkTimeOverScaleCount,
          };
          this.deviceData.push(item, item1, item2, item3);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    getAllOrderCountGroupByDate() {
      var data = {
        token: this.$cookie.get("token"),
        startTime:
          Date.parse(
            new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              "01" +
              " 00:00:00"
          ) /
            1000 +
          "",
        endTime: parseInt(new Date().getTime() / 1000) + "",
      };
      this.$api.getAllOrderCountGroupByDate(data).then((res) => {
        console.dir("月统计数据", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.setEachrts2Option(res.data.dataObject);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    regFenToYuan(fen) {
      var num = fen;
      num = fen * 0.01;
      num += "";
      var reg =
        num.indexOf(".") > -1
          ? /(\d{1,3})(?=(?:\d{3})+\.)/g
          : /(\d{1,3})(?=(?:\d{3})+$)/g;
      num = num.replace(reg, "$1");
      num = this.toDecimal2(num);
      return num;
    },
    toDecimal2(x) {
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf(".");
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + 2) {
        s += "0";
      }
      return s;
    },

    getCode(num) {
      if (isNaN(num)) {
        return 0;
      }
      return Math.round(num * 10000) / 100 + "%";
    },

    listShopCount() {
      var data = {
        token: this.$cookie.get("token"),
        startTime:
          Date.parse(
            new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              "01" +
              " 00:00:00"
          ) /
            1000 +
          "",
        endTime: parseInt(new Date().getTime() / 1000) + "",
      };
      this.$api.listShopCount(data).then((res) => {
        console.dir("本月销售分布", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.countPriceGroupByItemMappings =
            res.data.dataObject.countPriceGroupByDateMappings;
          this.sumPriceGroupByItemMappings =
            res.data.dataObject.sumPriceGroupByItemMappings;
          this.tosetChart();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    tosetChart() {
      //扇形图数据处理
      let sumPriceData = [];
      for (var i = 0; i < this.sumPriceGroupByItemMappings.length; i++) {
        var name = "";
        switch (this.sumPriceGroupByItemMappings[i].itemId) {
          case "1":
            name = "蔬菜类";
            break;
          case "2":
            name = "水果类";
            break;
          case "3":
            name = "猪肉类";
            break;
          case "4":
            name = "牛肉类";
            break;
          case "5":
            name = "水产类";
            break;
          case "6":
            name = "海鲜类";
            break;
          case "7":
            name = "冰鲜类";
            break;
          case "8":
            name = "干货类";
            break;
          case "9":
            name = "面食类";
            break;
          case "10":
            name = "卤菜类";
            break;
        }
        let sumdata = {
          value: this.regFenToYuan(this.sumPriceGroupByItemMappings[i].sum),
          name: name,
        };
        sumPriceData.push(sumdata);
      }
      // sumPriceData = [
      //   { value: 335, name: "优秀" },
      //   { value: 310, name: "良好" },
      //   { value: 234, name: "合格" },
      //   { value: 144, name: "不合格" },
      // ];

      // 饼状图
      const echarts3 = echarts.init(this.$refs.echarts3);
      const eachrts3Option = {
        title: {
          text: "本月销售金额分布",
          margin: 50,
          textStyle: {
            fontSize: 17,
            color: "#000000",
            fontWeight: "normal",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            console.log("销售", params);

            var result = "";

            // var dotHtml = "<span style='display:inline-block;margin-right:5px;margin-top:-5px;width:10px;height:10px;border-radius: 50%;backgroud:'></span>"   // 定义第一个数据前的长方形颜色
            result +=
              "<div style='margin-top:2px'>" +
              params.marker +
              "<span style='display:inline-block;margin-left:5px;'>" +
              params.name +
              ":" +
              "</div>" +
              "<div style='margin-top:3px'>" +
              " 销售金额: <span style='display:inline-block;margin-left:5px;'>" +
                params.value +
              "元" +
              "</div>" +
              "<div style='margin-top:3px'>" +
              " 销售占比: <span style='display:inline-block;margin-left:5px;'>" +
              params.percent +
              "%</span>" +
              "</div>";

            return result;
          },
        },
        color: [
          "#0f78f4",
          "#dd536b",
          "#9462e5",
          "#a6a6a6",
          "#e1bb22",
          "#39c362",
          "#3ed1cf",
        ],

        series: [
          {
            // name: "Access",
            type: "pie",
            radius: ["30%", "50%"],
            data: sumPriceData,
            label: {
              formatter: "{b}: {d}%",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
                formatter: "{b}: {d}%",
              },
            },
          },
        ],
      };

      echarts3.setOption(eachrts3Option);
    },

    /**柱状图处理 */
    setEachrts2Option(data) {
      // 柱状图

      //横向左边时间轴处理
      let date = new Date();
      let year = date.getFullYear();
      let day = date.getDate(); // 日
      let month = "";
      let nowday = "";
      const ChartData = [];
      console.dir("总时间循环", day);
      for (var i = 1; i < day + 1; i++) {
        month = date.getMonth() + 1; // 月
        if (month < 10) {
          month = "0" + month;
        } else {
          month = month;
        }

        if (i < 10) {
          nowday = "0" + i;
        } else {
          nowday = i;
        }
        console.dir("循环时间", year + "-" + month + "-" + nowday);
        if (i < 10) {
          var num = 0;
          var price = 0;

          for (var n = 0; n < data.countPriceGroupByDateMappings.length; n++) {
            var timeto = year + "-" + month + "-" + nowday;
            console.dir("当前循环时间", timeto);
            console.dir("数据时间", data.countPriceGroupByDateMappings[n].date);
            if (timeto == data.countPriceGroupByDateMappings[n].date) {
              num = data.countPriceGroupByDateMappings[n].count;
              console.dir("模拟num", num);
            }
          }
          for (var n = 0; n < data.sumPriceGroupByDateMappings.length; n++) {
            var timeto = year + "-" + month + "-" + nowday;
            console.dir("模拟timeto2", timeto);
            if (timeto == data.sumPriceGroupByDateMappings[n].date) {
              price = this.regFenToYuan(
                data.sumPriceGroupByDateMappings[n].sum
              );
            }
          }
          let aloneData = {
            time: month + "-0" + i,
            num: num,
            price: price,
          };
          ChartData.push(aloneData);
        } else {
          var num = 0;
          var price = 0;
          for (var n = 0; n < data.countPriceGroupByDateMappings.length; n++) {
            var timeto = year + "-" + month + "-" + nowday;
            console.dir("当前10-n循环时间", timeto);
            console.dir("数据时间", data.countPriceGroupByDateMappings[n].date);
            if (timeto == data.countPriceGroupByDateMappings[n].date) {
              num = data.countPriceGroupByDateMappings[n].count;
            }
          }
          for (var n = 0; n < data.sumPriceGroupByDateMappings.length; n++) {
            var timeto = year + "-" + month + "-" + nowday;
            if (timeto == data.sumPriceGroupByDateMappings[n].date) {
              price = this.regFenToYuan(
                data.sumPriceGroupByDateMappings[n].sum
              );
            }
          }
          let aloneData = {
            time: month + "-" + i,
            num: num,
            price: price,
          };
          ChartData.push(aloneData);
        }
      }
      console.dir("模拟5", ChartData);

      const echarts2 = echarts.init(this.$refs.echarts2);
      const eachrts2Option = {
        title: {
          text: "本月销售业绩",
          textStyle: {
            fontSize: 17,
            color: "#000000",
            fontWeight: "normal",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          data: ["销售金额", "销售笔数"],
        },
        xAxis: [
          {
            type: "category",
            data: ChartData.map((item) => item.time),
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "销售金额",
            axisLabel: {
              formatter: "{value} 元",
            },
          },
          {
            type: "value",
            name: "销售笔数",
            interval: 50,
            axisLabel: {
              formatter: "{value} 笔",
            },
          },
        ],
        color: ["#2ec7c9", "#b6a2de"],
        series: [
          {
            name: "销售金额",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " 元";
              },
            },
            data: ChartData.map((item) => item.price),
          },

          {
            name: "销售笔数",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " 笔";
              },
            },
            data: ChartData.map((item) => item.num),
          },
        ],
      };
      echarts2.setOption(eachrts2Option);
    },

    countEquitableRecord() {
      const data = { token: this.$cookie.get("token") };
      //   if (true) {
      //     data = {

      //     };
      //   } else {
      //     data = {
      //       token: this.$cookie.get("token"),
      //       marketId: "",
      //     };
      //   }

      this.$api.countEquitableRecord(data).then((res) => {
        console.dir("统计数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.dayCount = res.data.dataObject.dayCount;
          this.yearMonthCount = res.data.dataObject.yearMonthCount;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },

  mounted() {
    this.listMarkets(1, 10);
    this.getMarketsAccount();
    this.getAllOrderCountGroupByDate();
    this.countDevices();
    this.countEquitableRecord();
    this.listShopCount();

    getData().then(({ data }) => {
      const { tableData } = data.data;
      this.tableData = tableData;
      console.dir("模拟", this.tableData);

      // 基于准备好的dom，初始化echarts实例
      const echarts1 = echarts.init(this.$refs.echarts1);
      // 指定图表的配置项和数据
      var echarts1Option = {};
      // 处理数据xAxis
      const { orderData, userData, videoData } = data.data;
      const xAxis = Object.keys(orderData.data[0]);
      const xAxisData = {
        data: xAxis,
      };
      echarts1Option.xAxis = xAxisData;
      echarts1Option.yAxis = {};
      echarts1Option.legend = xAxisData;
      echarts1Option.series = [];
      xAxis.forEach((key) => {
        echarts1Option.series.push({
          name: key,
          data: orderData.data.map((item) => item[key]),
          type: "line",
        });
      });

      console.dir(
        "模拟1",
        userData.map((item) => item.date)
      );
    });
  },
};
</script>
  <style lang="less" scoped>
.user {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  img {
    margin-right: 40px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .userinfo {
    .name {
      font-size: 32px;
      margin-bottom: 10px;
    }
    .access {
      color: #999999;
    }
  }
}
.login-info {
  p {
    line-height: 28px;
    font-size: 14px;
    color: #999999;
    span {
      color: #666666;
      margin-left: 60px;
    }
  }
}

.device {
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1%;
  .icon {
    width: 80px;
    height: 80px;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    color: #fff;
  }
  .detail {
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .desc {
      height: 40px;
      font-size: 22px;
      color: #000;
      font-weight: bold;
    }
  }
  .detail1 {
    display: flex;
    flex-direction: row;
    .desc {
      font-size: 14px;
      color: #999;
      font-weight: normal;
    }
  }
}

.num {
  height: 120px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1%;
  .icon {
    width: 80px;
    height: 80px;
    font-size: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 80px;
    color: #fff;
  }
  .detail {
    margin-left: 15px;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .price {
      font-size: 24px;
      margin-top: 1%;
      line-height: 30px;
      font-weight: bold;
      height: 30px;
    }
    .desc {
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
  }
  .detail1 {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    .desc {
      font-size: 14px;
      color: #535252;
    }
  }
  .el-card {
    width: 24%;
    margin-bottom: 20px;
  }
}
.graph {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
}
.graph1 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>