<template>
  <div class="header-container">
    <div class="l-content">
      <el-button
        style="margin-right: 20px"
        @click="handleMenu"
        icon="el-icon-menu"
        size="mini"
      ></el-button>
      <!-- 面包屑 -->
      <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item
          v-for="item in tags"
          :key="item.path"
          :to="{ path: item.path }"
          >{{ item.label }}</el-breadcrumb-item
        >
      </el-breadcrumb> -->
    </div>

    <div class="manage">
      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
      >
        <div
          slot="title"
          class="header-title"
          :style="{
            background: 'white',
            color: 'black',
            'font-weight': 'bold',
          }"
        >
          <div
            style="
              border-left: 4px solid rgb(54, 161, 233);
              width: 90px;
              font-weight: bold;
              text-align: center;
            "
          >
            修改密码
          </div>
        </div>

        <!-- 用户的表单信息 -->
        <el-form ref="form" :rules="rules" :model="form" label-width="160px">
          <el-form-item label="旧密码：" prop="oldpassword">
            <el-input
              placeholder="请输入旧密码"
              v-model="form.oldpassword"
            ></el-input>
          </el-form-item>

          <el-form-item label="新密码：" prop="newpassword">
            <el-input
              placeholder="请输入新密码"
              v-model="form.newpassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="newpasswordagain">
            <el-input
              placeholder="请确认新密码"
              v-model="form.newpasswordagain"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel1">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="time">
      <div style="margin-right: 20px">{{ nowDate }}</div>

      <div class="r-content">
        <el-dropdown @command="handleClick">
          <img class="user1" src="../assets/images/user.png" alt="" />

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="changepassword"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item command="cancel">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Cookie from "js-cookie";
import md5 from "../utils/md5";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        oldpassword: "",
        newpassword: "",
        newpasswordagain: "",
      },
      rules: {
        oldpassword: [{ required: true, message: "请输入旧密码" }],
        newpassword: [{ required: true, message: "请输入新密码" }],
        newpasswordagain: [{ required: true, message: "请确认新密码" }],
      },
      nowDate: "", // 当前日期
    };
  },
  methods: {
    // 提交用户表单
    submit() {
      console.dir("密码1", "开始");
      this.$refs.form.validate((valid) => {
        // console.log(valid, 'valid')
        if (valid) {
          // 后续对表单数据的处理
          // 新增管理
          if (this.form.newpassword === this.form.newpasswordagain) {
            var data = {
              token: this.$cookie.get("token"),
              loginPassword: md5(this.form.oldpassword),
              newerLoginPassword: md5(this.form.newpassword),
            };
            this.$api.updateRootManagerPasswordBySelf(data).then((res) => {
              console.dir("密码", res);
              if (res.status === 200 && res.data.codeId === 1) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                // 清空表单的数据
                this.$refs.form.resetFields();
                // 关闭弹窗
                this.dialogVisible = false;
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            this.$message({
              type: "error",
              message: "两次密码不一致!",
            });
          }
        }
      });
    },
    // 弹窗关闭的时候
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    cancel1() {
      this.$refs.form.resetFields();
      this.handleClose();
    },
    changepassword() {
      this.dialogVisible = true;
    },

    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
    handleMenu() {
      this.$store.commit("collapseMenu");
    },
    handleClick(command) {
      if (command === "cancel") {
        // 清除cookie中的token
        Cookie.remove("token");
        // 清除cookie中的menu
        Cookie.remove("menu");
        // 跳转到登录页
        this.$router.push("/login");
      } else if (command === "changepassword") {
        this.dialogVisible = true;
      }
    },
  },
  mounted() {
    this.currentTime();
  },
  // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
  },
};
</script>
<style lang="less" scoped>
.header-container {
  padding: 0 20px;
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .text {
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
  }
  .r-content {
    .user {
      height: 40px;
      border-radius: 50%;
    }
  }
  .user1 {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .time {
    display: flex;
    align-items: center;
    color: #fff;
  }
  .l-content {
    display: flex;
    align-items: center;
    /deep/.el-breadcrumb__item {
      .el-breadcrumb__inner {
        font-weight: normal;
        &.is-link {
          color: #666;
        }
      }
      &:last-child {
        .el-breadcrumb__inner {
          color: #fff;
        }
      }
    }
  }
}
</style>