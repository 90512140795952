<template>
  <div class="query">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="姓名：">
        <el-input
          v-model="formInline.managerName"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号：">
        <el-input
          v-model="formInline.loginName"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          style="background: #ffffff; color: #000; border-color: #dddddd"
          @click="onRest"
          >重置</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onCheck">查询</el-button>
      </el-form-item>
    </el-form>

    <div class="manage">
      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div
          slot="title"
          class="header-title"
          :style="{
            background: 'white',
            color: 'black',
            'font-weight': 'bold',
          }"
        >
          <div
            style="
              border-left: 4px solid rgb(54, 161, 233);
              width: 90px;
              font-weight: bold;
              text-align: center;
            "
          >
            {{ dialogTitle }}
          </div>
        </div>

        <!-- 用户的表单信息 -->
        <el-form ref="form" :rules="rules" :model="form" label-width="160px">
          <el-form-item label="市场管理名称：" prop="managerGroupName">
            <el-input
              placeholder="请输入市场管理名称"
              v-model="form.managerGroupName"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名：" prop="managerName">
            <el-input
              placeholder="请输入姓名"
              v-model="form.managerName"
            ></el-input>
          </el-form-item>

          <el-form-item label="联系电话：" prop="managerLinkNo">
            <el-input
              placeholder="请输入联系电话"
              v-model="form.managerLinkNo"
            ></el-input>
          </el-form-item>
          <el-form-item label="登录账号：" prop="loginName">
            <el-input
              placeholder="请输入登录账号"
              v-model="form.loginName"
            ></el-input>
          </el-form-item>
          <el-form-item label="登录密码：" prop="loginPassword">
            <el-input
              placeholder="admin123456"
              :disabled="true"
              v-model="admin123456"
              value="admin123456"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
      <div class="manage-header">
        <el-button @click="handleAdd" type="primary"> + 新增 </el-button>
      </div>
    </div>

    <div class="common-tabel">
      <el-table :data="tableData" height="620px">
        <el-table-column prop="managerGroupName" label="市场管理名称">
        </el-table-column>
        <el-table-column prop="managerName" label="管理人姓名">
        </el-table-column>
        <el-table-column prop="loginName" label="登录账号"> </el-table-column>
        <el-table-column prop="managerLinkNo" label="联系电话">
        </el-table-column>
        <el-table-column prop="addr" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="restPassword(scope.row)"
              >重置密码</el-button
            >
            <el-button size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <!-- <el-pagination
          style="margin-top: 20px"
          layout="->,prev, pager, next"
          :total="total"
          @current-change="handlePage"
        >
        </el-pagination> -->
        <!--分页按钮-->
        <el-pagination
          background
          layout="prev, pager, next,jumper"
          :total="total"
          @current-change="handlePage"
          :current-page="pageData.page"
          :page-size="pageData.size"
          style="text-align: center"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  <script>
import { getUser, addUser, editUser, delUser } from "../../api";
import md5 from "../../utils/md5";
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不可为空"));
      } else {
        if (value !== "") {
          var reg = /^1[3-9]\d{9}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的手机号码"));
          }
        }
        callback();
      }
    };

    return {
      formInline: {
        managerName: "",
        loginName: "",
      },
      dialogVisible: false,
      form: {
        loginName: "",
        loginPassword: "admin123456",
        managerGroupName: "",
        managerLinkNo: "",
        managerName: "",
        rootMarketManagerId: "",
      },
      editForm: {
        loginName: "",
        loginPassword: "admin123456",
        managerGroupName: "",
        managerLinkNo: "",
        managerName: "",
        rootMarketManagerId: "",
      },
      rules: {
        managerGroupName: [{ required: true, message: "请输入市场管理名称" }],
        managerName: [{ required: true, message: "请输入姓名" }],
        managerLinkNo: [
          { required: true, message: "请输入联系电话" },
          {
            validator: checkMobile,
            min: 11,
            max: 11,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        loginName: [{ required: true, message: "请输入登录账号" }],
      },
      tableData: [],
      modalType: 0, // 0表示新增的弹窗， 1表示编辑
      dialogTitle: "",
      total: 0, //当前的总条数
      pageData: {
        page: 1,
        limit: 10,
      },
      userForm: {
        name: "",
      },
    };
  },
  methods: {
    /**
     * 获取市场管理员列表数据
     */
    getList(page) {
      this.tableData = []
      var data = {
        token: this.$cookie.get("token"),
        loginName: "",
        managerName: "",
        page: page,
        size: 10,
        updateInMarch: "1",
      };
      this.$api.getMarketManagerByPage(data).then((res) => {
        console.dir("返回数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.tableData = res.data.dataObject.data;
          this.total = res.data.dataObject.pageTotal * this.pageData.limit;
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },

    // 选择页码的回调函数
    handlePage(val) {
      this.pageData.page = val;
      console.dir("返回数据：", val);
      this.getList(val);
    },

    /** 重置搜索选项*/
    onRest() {
      this.formInline.loginName = "";
      this.formInline.managerName = "";
      this.getList(1);
    },
    /** 列表模糊查询*/
    onCheck() {
      var data = {
        token: this.$cookie.get("token"),
        loginName: this.formInline.loginName,
        managerName: this.formInline.managerName,
        page: 1,
        size: 10,
        updateInMarch: "1",
      };
      this.$api.getMarketManagerByPage(data).then((res) => {
        console.dir("返回数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.tableData = res.data.dataObject.data;
          console.dir("返回数据：", this.pageData.limit);
          this.total = res.data.dataObject.pageTotal * this.pageData.limit;
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },

    /**
     * 重置密码
     */
    restPassword(row) {
      this.$confirm("确定要重置此用户密码为admin123456?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.dir(row);

          var data = {
            token: this.$cookie.get("token"),
            marketManagerId: row.marketManagerId,
            loginPassword: md5("admin123456"),
            // loginName: "",
            // managerName: "",
            // managerLinkNo: "",
            // managerGroupName: "",
          };
          this.$api.updateMarketManagerInfo(data).then((res) => {
            console.dir("返回数据：", res);
            if (res.status === 200 && res.data.codeId === 1) {
              this.$message({
                type: "success",
                message: "重置密码成功!",
              });
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    /**新增管理员 */
    handleAdd() {
      this.form = {
        loginName: "",
        loginPassword: "",
        managerGroupName: "",
        managerLinkNo: "",
        managerName: "",
        rootMarketManagerId: "",
      };
      this.modalType = 0;
      this.dialogTitle = "新增用户";
      this.dialogVisible = true;
    },
    /**编辑管理员 */
    handleEdit(row) {
      // 注意需要对当前行数据进行深拷贝，否则会出错
      this.form = JSON.parse(JSON.stringify(row));
      this.editForm = JSON.parse(JSON.stringify(row));
      this.modalType = 1;
      this.dialogTitle = "编辑用户";
      this.dialogVisible = true;
    },

    // 提交用户表单
    submit() {
      this.$refs.form.validate((valid) => {
        // console.log(valid, 'valid')
        if (valid) {
          // 后续对表单数据的处理
          if (this.modalType === 0) {
            // 新增管理
            var data = {
              token: this.$cookie.get("token"),
              loginPassword: md5("admin123456"),
              loginName: this.form.loginName,
              managerName: this.form.managerName,
              managerLinkNo: this.form.managerLinkNo,
              managerGroupName: this.form.managerGroupName,
            };
            this.$api.createMarketManagerAccount(data).then((res) => {
              console.dir("返回数据：", res);
              if (res.status === 200 && res.data.codeId === 1) {
                this.$message({
                  type: "success",
                  message: "新增管理员信息成功!",
                });
                this.getList(1);
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            // 编辑
            const data = {
              token: this.$cookie.get("token"),
              marketManagerId: this.form.marketManagerId,
              loginPassword: md5("admin123456"),
              loginName: this.form.loginName,
              managerName: this.form.managerName,
              managerLinkNo: this.form.managerLinkNo,
              managerGroupName: this.form.managerGroupName,
            };

            if (this.form.loginName == this.editForm.loginName) {
              this.$delete(data, "loginName");
            }

            this.$api.updateMarketManagerInfo(data).then((res) => {
              console.dir("返回数据：", res);
              if (res.status === 200 && res.data.codeId === 1) {
                this.$message({
                  type: "success",
                  message: "更新管理员信息成功!",
                });
                this.getList(this.pageData.page);
                this.$refs.form.resetFields();
              } else {
                this.$message.error(res.data.message);
              }
            });
          }

          // 清空表单的数据
          this.$refs.form.resetFields();
          // 关闭弹窗
          this.dialogVisible = false;
        }
      });
    },

    handleDelete(row) {
      this.$confirm("确定要删除该用户么", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.dir("1", row);

          var data = {
            token: this.$cookie.get("token"),
            marketManagerId: row.marketManagerId,
          };
          this.$api.deleteMarketManagerInfo(data).then((res) => {
            console.dir("返回数据：", res);
            if (res.status === 200 && res.data.codeId === 1) {
              this.$message({
                type: "success",
                message: "删除用户成功",
              });
              
              this.getList(1);
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 弹窗关闭的时候
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    cancel() {
      this.$refs.form.resetFields();
      this.handleClose();
    },
  },
  mounted() {
    this.getList(1);
  },
};
</script>
  <style lang="less" scoped>
.manage {
  height: 90%;
  .el-dialog__title {
    color: brown;
  }
  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .common-tabel {
    position: relative;
    height: calc(100% - 62px);
    overflow: auto;
    .pager {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
  