import RSA from "./rsa";

//移动端私钥
var privateKey = '-----BEGIN PRIVATE KEY-----MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBALEox42SjkIIABMjyMc4XfGz4lYLCUwSbdILdkzHe8qL+/hhoIt4AIHe5X50BqqKUSA3xsh/sZM+30ZV+ikEY+9p4tKqgZW8k7DHAK6FTC5LouFhUkbY2eZLCqsXxlYLYvhJJQqeFKzmQ2xTRhnRxpVqdhwI0y9s+9e71Tsvjr+TAgMBAAECgYEAmBTt2fbp8H11sviRL5SP8SH/NuYagReA90Ontpm5B1PMZplIfPHzXYm/cSKhZl1+7y1ylEQqaMEcYwED05pVPo5HNF4SSoHgp3MYC5xVG+6nXbHILf17JzV5arlzIX4VpYLQZqrxjXSwPiBiYiRTq1hYEyxd5Lgagi5HiwVFQsECQQDqqP/fYt4ckDluRDGTacnwh0eTghcAFOrSPQ4o0ppWZWH4W034kiBSFLXwWypa8ZtPQKNBCWC4zJ+Z6bieIDl5AkEAwUUh7S442lWC3n/PpkFKust1lA1hYgUC0oObIvscCluwc06ZVtM7uAf3q9j+55kwAAAuTI+voBNcbBBX5pgKawJBAN9H9UgCVCPLm8nu+1kwBOVoY1PZKbd7L8DiNZHkatSgXSsq/bLyn+xaJpBQvqmQUV6Eu5JLsQvGMGsMOA3FzckCQFfeeoI/FZIOdqV6LopD/RnYDuj0/GV4KcRUGWBaEnGTJjIGdijHeMs+tBfpXFsnhm85pdMhMJPyuX5YH96zGWkCQQCKQL1CaTK8AwU2tWVomP2ITRtNve1X455dBf4CQ/F5fGo6oVooUcA9vTjyImo8shePFYz4xPocm3iwdAs1oMmo-----END PRIVATE KEY-----'
//移动端公钥
var publicKey = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC2WcNtIujv8mU6OJnUgj6PL0K02uUs9effGT4Exjj1a1MCkgVgjNSWMKTES4FSXKmpZAm2a0u5fJuPjJ2hUkgWOghQkjbZmB/hZbOWIUV1mupXMRmXV/a83fERrZJ0kou7Gd3LQ2wCIJlhMvY6kD2dkhuAIcysNYPGS1TGP8c68wIDAQAB-----END PUBLIC KEY-----'


function formatTime(date) {
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()

  var hour = date.getHours()
  var minute = date.getMinutes()
  var second = date.getSeconds()


  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

/**
 * 供外部分段私钥加密使用
 */
function encryptByPrivateKeyForSpilt(str) {
  var DEFAULT_KEY_SIZE = 1024 // 秘钥默认长度
  var DEFAULT_SPLIT = "#PART#" // 当要加密的内容超过bufferSize，则采用partSplit进行分块加密
  var DEFAULT_BUFFERSIZE = (DEFAULT_KEY_SIZE / 8) - 11 // 当前秘钥支持加密的最大字节数

  // var encrypt_rsa = new RSA.RSAKey();
  // encrypt_rsa = RSA.KEYUTIL.getKey(rsaPrivateKey);
  var lenth = str.length //需要加密的字符串长度
  // console.log("需要加密的字符串长度:")
  // console.log(lenth)
  // console.log("允许加密的字符串长度:")
  // console.log(DEFAULT_BUFFERSIZE)
  var num = 0 //需要加密的次数
  var encStr = ""

  if (lenth % DEFAULT_BUFFERSIZE == 0) {
    num = parseInt(lenth / DEFAULT_BUFFERSIZE)

  } else {
    num = parseInt(lenth / DEFAULT_BUFFERSIZE + 1)
  }

  if (lenth > DEFAULT_BUFFERSIZE) {
    for (var i = 0; i < num; i++) {
      var s = ""
      if (i == num - 1) {
        s = Jia_mi(str.substring(i * DEFAULT_BUFFERSIZE, lenth))
      } else {
        s = Jia_mi(str.substring(i * DEFAULT_BUFFERSIZE, i * DEFAULT_BUFFERSIZE + DEFAULT_BUFFERSIZE)) + "235041525423"
      }
      encStr = encStr + s
    }
  } else {
    encStr = Jia_mi(str);
  }
  // console.log("加密结果未b64：")
  // console.log(encStr)

  encStr = RSA.hex2b64(encStr);

  // console.log("加密结果：")
  // console.log(encStr)
  return encStr;

  // decryptByPublicKeyForSpilt(encStr)
}

/**
 * 供外部分段公钥解密使用
 */
function decryptByPublicKeyForSpilt(str) {

  var encStr1 = RSA.b64tohex(str);
  console.log("解密hex"  , encStr1)
  var DEFAULT_KEY_SIZE = 256 // 分段解密长度

  var DEFAULT_SPLIT = "#PART#" // 当要加密的内容超过bufferSize，则采用partSplit进行分块加密
  var DEFAULT_BUFFERSIZE = (DEFAULT_KEY_SIZE / 8) - 11 // 当前秘钥支持加密的最大字节数
  let a = []
  var arr = encStr1.split("235041525423");
  console.log("解密arr"  , arr)
  var encStr = []
  for (var i = 0; i < arr.length; i++) {
    encStr = encStr.concat(Jie_mi1(arr[i]))
  }

  return byteToString(encStr)
}

/**
 * 供外部分段公钥解密使用
 */
function decryptByPublicKeyForSpilt1(str) {
  // var str = "XVcdIl5PZptDOGsYXn/WT61YV9WjWVmX5B/EM1stV6vUz1/c4b4rV+qY9BQbl0y0hC3pQ/wif8E2AZRF5PPHitE+jMRnUwqNrv4VR6e/qWHDlCm206CwRI7Jx/qc/D+coIBjrDNf8vtXiSN2Ukijpnt95rCbSWoadCHaGx3yRi8jUEFSVCN96DPU982WW1vnlxOZTIVZb5XKBziAXNna1RFZ8kXKCKglSGIQ0J2irSkXB9NabPEj3BOPgAwGawU8mmiptaro48YkofxWDUdyLTDTleUCqMxDwiUB7P2WdH+JYdR5dHjx+k+xtVpNCtT+sqiry6Cwc0lbOvI9FFIPgNXvwTRvrSNQQVJUI57Gn2thCAKznr9LZsMd3nNHWgeDf+moSub6RRNcoRzfzCHMrOPT6tAM/ZYiZsxSa7IBi86XIS3VK6/F8eLa+2yHzjs+tsgZ8YLo9wL1nbIohpSfJ7bto7H34Y5HQENe7RnekiG76l7jJuTZcI5EfUwSCODkptaD3FnBTLYDyM6WI1BBUlQjWNeMnd+r0j6l2DJkSRAxb78DPOgZjJgGrZ+xz/DmNSLbwNDKKWsHX7pjyUhinR+YfDWwQ2G7KYkhYiqID0Qpi4Y/LDhowJoiUZRMG2cjhAkNGSZM09BTg9xUoXwx3ls8y8bw3PyLsSlMQuhSsNpE7dxFlygi6zX4nBDGQK1aOg4="
  var str = "pLVFAoPZa9GfXerbFgaUiWKqRGwXoaDVFG0h4dGYFKGRq1GCp8839ZABL9HFn33F1NwbGgYP2qes9wBwPuibrFEZcIYzL4WUHI5Zruq4E8PcDrZHWLru0gP7VwhiWwsd"
  var encStr1 = RSA.b64tohex(str);
  console.log("解密hex"  , encStr1)
  var DEFAULT_KEY_SIZE = 256 // 分段解密长度

  var DEFAULT_SPLIT = "#PART#" // 当要加密的内容超过bufferSize，则采用partSplit进行分块加密
  var DEFAULT_BUFFERSIZE = (DEFAULT_KEY_SIZE / 8) - 11 // 当前秘钥支持加密的最大字节数

  var arr = encStr1.split("235041525423");
  console.log("解密arr", arr)
  console.log("解密段数长度：", arr)
  var encStr = []
  for (var i = 0; i < arr.length; i++) {
    encStr = encStr.concat(Jie_mi1(arr[i]))
  }

  // console.log("解密arr1"  , Bytes2HexString(encStr))
  // encStr = "7b2266726565526566756e644c696d6974223a3330303030302c2267656e646572223a312c226964223a3532332c226c6173744c6f67696e54696d65223a22323032302d31322d32382031373a33333a3236222c226e69636b6e616d65223a22e5b9bfe5918a222c22706572736973746564223a747275652c2270686f6e654e6f223a223133303538303130343335222c22726567697374657254696d65223a22323032302d30372d30332031343a34383a3533222c2273656c6c65724964223a3532332c2273656c6c657254797065223a312c2273686f7041646472657373223a22e58c97e4baace5b88220e58c97e4baace5b88220e4b89ce59f8ee58cba20e5a4b4e58f91e69f93e58f91e5a49ae5b091e992b1e4b880e4b8aae69c88222c2273686f704e616d65223a22e58c97e4baace5b88220e58c97e4baace5b882222c2273686f7054656c6570686f6e654e6f223a223133353131313132323232227d"

  console.log("解密arr2", byteToString(encStr))
  console.log("解密arr3", utf8ByteToUnicodeStr(encStr))


  return Bytes2HexString(encStr)
}

function utf8ByteToUnicodeStr(utf8Bytes) {
  var unicodeStr = "";
  for (var pos = 0; pos < utf8Bytes.length;) {
    var flag = utf8Bytes[pos];
    var unicode = 0;
    if ((flag >>> 7) === 0) {
      unicodeStr += String.fromCharCode(utf8Bytes[pos]);
      pos += 1;

    } else if ((flag & 0xFC) === 0xFC) {
      unicode = (utf8Bytes[pos] & 0x3) << 30;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 24;
      unicode |= (utf8Bytes[pos + 2] & 0x3F) << 18;
      unicode |= (utf8Bytes[pos + 3] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos + 4] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 5] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 6;

    } else if ((flag & 0xF8) === 0xF8) {
      unicode = (utf8Bytes[pos] & 0x7) << 24;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 18;
      unicode |= (utf8Bytes[pos + 2] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos + 3] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 4] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 5;

    } else if ((flag & 0xF0) === 0xF0) {
      unicode = (utf8Bytes[pos] & 0xF) << 18;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos + 2] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 3] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 4;

    } else if ((flag & 0xE0) === 0xE0) {
      unicode = (utf8Bytes[pos] & 0x1F) << 12;;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 2] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 3;

    } else if ((flag & 0xC0) === 0xC0) { //110
      unicode = (utf8Bytes[pos] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 1] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 2;

    } else {
      unicodeStr += String.fromCharCode(utf8Bytes[pos]);
      pos += 1;
    }
  }
  return unicodeStr;
}


//字节数组转十六进制字符串，对负值填坑
function Bytes2HexString(arrBytes) {
  var str = "";
  for (var i = 0; i < arrBytes.length; i++) {
    var tmp;
    var num = arrBytes[i];
    if (num < 0) {
      //此处填坑，当byte因为符合位导致数值为负时候，需要对数据进行处理
      tmp = (255 + num + 1).toString(16);
    } else {
      tmp = num.toString(16);
    }
    if (tmp.length == 1) {
      tmp = "0" + tmp;
    }
    str += tmp;
  }
  return str;
}


function byteToString(arr) {
  if (typeof arr === 'string') {
    return arr;
  }
  var str = '',
    _arr = arr;
  for (var i = 0; i < _arr.length; i++) {
    var one = _arr[i].toString(2),
      v = one.match(/^1+?(?=0)/);
    if (v && one.length == 8) {
      var bytesLength = v[0].length;
      var store = _arr[i].toString(2).slice(7 - bytesLength);
      for (var st = 1; st < bytesLength; st++) {
        store += _arr[st + i].toString(2).slice(2);
      }
      str += String.fromCharCode(parseInt(store, 2));
      i += bytesLength - 1;
    } else {
      str += String.fromCharCode(_arr[i]);
    }
  }
  return str;
}

//十六进制字符串转字节数组
function Str2Bytes(str) {

  var pos = 0;

  var len = str.length;

  if (len % 2 != 0) {

    return null;

  }

  len /= 2;

  var hexA = new Array();

  for (var i = 0; i < len; i++) {

    var s = str.substr(pos, 2);

    var v = parseInt(s, 16);

    hexA.push(v);

    pos += 2;

  }

  return hexA;

}


/**
 * 加签使用
 */
function jiaqian(str) {
  // console.log('加签RSA1:')
  var sign_rsa = new RSA.RSAKey();
  sign_rsa = RSA.KEYUTIL.getKey(privateKey);
  // console.log('加签RSA:')
  // console.log(sign_rsa)
  var hashAlg = 'sha1';
  var Sig = sign_rsa.signString("signData", hashAlg);
  Sig = RSA.hex2b64(Sig); // hex 转 b64
  // console.log("加签结果：" + Sig)

}


//验签
function yanqian(str) {
  var verify_rsa = new RSA.RSAKey();
  verify_rsa = RSA.KEYUTIL.getKey(publicKey);
  if (Sig == "") {
    wx.showToast({
      title: '请先验签',
      icon: 'loading',
      duration: 1000
    })
  } else {
    Sig = RSA.b64tohex(Sig)
    var ver = verify_rsa.verifyString("signData", Sig)
    console.log('验签结果：' + ver)
    this.setData({
      output: Sig
    })
  }
}

/**
 * 供外部分段加密使用
 */
function Jia_mi(str) {
  var input_rsa = str;
  var encStr = ""
  // console.log("加密分段：", input_rsa)
  // console.log("分段长度：", input_rsa.length)
  var encrypt_rsa = RSA.RSAKey();
  encrypt_rsa = RSA.KEYUTIL.getKey(publicKey);
  encStr = encrypt_rsa.encrypt(input_rsa)
  // console.log("加密结果：")
  // console.log(encStr)
  return encStr
}

/**
 * 供外部分段解密使用
 */
function Jie_mi(str) {
  var decrypt_rsa = new RSA.RSAKey();
  var decrypt_rsa = RSA.KEYUTIL.getKey(privateKey);
  // var encStr = RSA.b64tohex(str);
  var encStr = str;
  console.log("解密开始：")
  console.log(encStr) 
  console.log(encStr.length)
  var decStr = decrypt_rsa.decrypt(encStr)
  console.log("解密结果：")
  console.log(decStr)
  return decStr
}

/**
 * 供外部分段解密使用
 */
function Jie_mi1(str) {
  var decrypt_rsa = new RSA.RSAKey();
  decrypt_rsa = RSA.KEYUTIL.getKey(privateKey);
  // var encStr = RSA.b64tohex(str);
  var encStr = str;
  // console.log("解密开始：")
  // console.log(encStr)
  // console.log(encStr.length)

  var decStr = decrypt_rsa.decrypt1(encStr)

  // console.log("解密结果：")
  // console.log(decStr)
  return decStr

}

function myStringtoHex(str) {
  str = str.toLowerCase();
  let newBuffer = new ArrayBuffer(str.length / 2);
  let hexBuffer = new Uint8Array(newBuffer, 0);
  let h = 0,
    l = 0;
  for (let i = 0; i < str.length / 2; i++) {
    h = str.charCodeAt(2 * i);

    l = str.charCodeAt(2 * i + 1);
    if (48 <= h && h <= 57) {
      h = h - 48;
    } else {
      h = h - 97 + 10;
    }
    if (48 <= l && l <= 57) {
      l = l - 48;
    } else {
      l = l - 97 + 10;
    }
    hexBuffer[i] = h * 16 + l;
  }
  return hexBuffer;
}

function myHextoString(hex) {
  let out = '';
  for (let i = 0; i < hex.length; i++) {
    let h = (hex[i] / 16);
    if (h >= 10) {
      h = h - 10 + 97;
    } else {
      h = h + 48;
    }
    h = String.fromCharCode(h);
    out += h;

    let l = hex[i] % 16;
    if (l >= 10) {
      l = l - 10 + 97;
    } else {
      l = l + 48;
    }
    l = String.fromCharCode(l);
    out += l;

  }
  return out
}

/*! (c) Tom Wu | http://www-cs-students.stanford.edu/~tjw/jsbn/
 */
var b64map = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
var b64pad = "=";

function hex2b64(d) {
  var b;
  var e;
  var a = "";
  for (b = 0; b + 3 <= d.length; b += 3) {
    e = parseInt(d.substring(b, b + 3), 16);
    a += b64map.charAt(e >> 6) + b64map.charAt(e & 63)
  }
  if (b + 1 == d.length) {
    e = parseInt(d.substring(b, b + 1), 16);
    a += b64map.charAt(e << 2)
  } else {
    if (b + 2 == d.length) {
      e = parseInt(d.substring(b, b + 2), 16);
      a += b64map.charAt(e >> 2) + b64map.charAt((e & 3) << 4)
    }
  }
  if (b64pad) {
    while ((a.length & 3) > 0) {
      a += b64pad
    }
  }
  return a
}

function b64tohex(f) {
  var d = "";
  var e;
  var b = 0;
  var c;
  var a;
  for (e = 0; e < f.length; ++e) {
    if (f.charAt(e) == b64pad) {
      break
    }
    a = b64map.indexOf(f.charAt(e));
    if (a < 0) {
      continue
    }
    if (b == 0) {
      d += int2char(a >> 2);
      c = a & 3;
      b = 1
    } else {
      if (b == 1) {
        d += int2char((c << 2) | (a >> 4));
        c = a & 15;
        b = 2
      } else {
        if (b == 2) {
          d += int2char(c);
          d += int2char(a >> 2);
          c = a & 3;
          b = 3
        } else {
          d += int2char((c << 2) | (a >> 4));
          d += int2char(a & 15);
          b = 0
        }
      }
    }
  }
  if (b == 1) {
    d += int2char(c << 2)
  }
  return d
}


export default {
  encryptByPrivateKeyForSpilt,
  decryptByPublicKeyForSpilt,
  decryptByPublicKeyForSpilt1,
  formatTime,
  myHextoString,
  myStringtoHex,
  hex2b64,
  b64tohex,
  Jia_mi,
  Jie_mi,
  Jie_mi1,
  jiaqian,
  yanqian

}