import Vue from 'vue'
import App from './App.vue'
// import { Row, Button } from 'element-ui';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import './api/mock'
import Cookie from 'js-cookie'
import axios from 'axios'

import Plugin from 'v-fit-columns';
Vue.use(Plugin);

Vue.prototype.$axios = axios
axios.defaults.baseURL = '/api'        //关键代码

import api from './utils/api'//暴露全局方法（使用:this$api）
Vue.prototype.$api = api;
Vue.prototype.$cookie = Cookie;

Vue.config.productionTip = false

// 全局引入
Vue.use(ElementUI)
Vue.directive("loadmore", {
  bind(el, binding, vnode) {
      const SELECTWRAP = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
      );
      SELECTWRAP.addEventListener("scroll", function () {
          // scrollTop 这里可能因为浏览器缩放存在小数点的情况，导致了滚动到底部时
          // scrollHeight 减去滚动到底部时的scrollTop ，依然大于clientHeight 导致无法请求更多数据
          // 这里将scrollTop向上取整 保证滚到底部时，触发调用
          const CONDITION = this.scrollHeight - Math.ceil(this.scrollTop) <= this.clientHeight;
          // el.scrollTop !== 0 当输入时，如果搜索结果很少，以至于没看到滚动条，那么此时的CONDITION计算结果是true，会执行bind.value()，此时不应该执行，否则搜索结果不匹配
          if (CONDITION && this.scrollTop !== 0) {
              binding.value();
          }
      });
  },
});


// 按需引入
// Vue.use(Row)
// Vue.use(Button)

// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  // 判断token存不存在
  const token = Cookie.get('token')
  // token不存在，说明当前用户是未登录，应该跳转至登录页
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else if (token && to.name === 'login') { // token存在，说明用户登录，此时跳转至首页
    next({ name: 'home' })
  } else {
    next()
  }
})



new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    store.commit('addMenu', router)
  }
}).$mount('#app')
