import Mock from 'mockjs'
export default {
    getMenu: config => {
        const { username, password } = JSON.parse(config.body)
        // 先判断用户是否存在
        // 判断账号和密码是否对应
        if (username === 'test1' && password === 'test1') {//市场管理
            return {
                code: 20000,
                data: {
                    menu: [
                        {
                            path: '/home',
                            name: 'home',
                            label: '首页',
                            icon: 's-home',
                            url: 'Home.vue'
                        },
                        {
                            path: '/mall',
                            name: 'mall',
                            label: '商品管理',
                            icon: 'video-play',
                            url: 'Mall.vue'
                        },
                        {
                            path: '/user',
                            name: 'user',
                            label: '用户管理',
                            icon: 'user',
                            url: 'User.vue'
                        },
                        {
                            label: '其他',
                            icon: 'location',
                            children: [
                                {
                                    path: '/page1',
                                    name: 'page1',
                                    label: '页面1',
                                    icon: 'setting',
                                    url: 'PageOne.vue'
                                },
                                {
                                    path: '/page2',
                                    name: 'page2',
                                    label: '页面2',
                                    icon: 'setting',
                                    url: 'PageTwo.vue'
                                }
                            ]
                        }
                    ],
                    token: Mock.Random.guid(),
                    message: '获取成功'
                }
            }
        } else if (username === 'test2' && password === 'test2') {//市场监管
            return {
                code: 20000,
                data: {
                    menu: [
                        {
                            path: '/home',
                            name: 'home',
                            label: '首页',
                            icon: 's-home',
                            url: 'Home.vue'
                        },
                        {
                            path: '/video',
                            name: 'video',
                            label: '商品管理',
                            icon: 'video-play',
                            url: 'Mall.vue'
                        }
                    ],
                    token: Mock.Random.guid(),
                    message: '获取成功'
                }
            }
        } else if (username === 'test3' && password === 'test3') {//系统超管
            return {
                code: 20000,
                data: {
                    menu: [
                        {
                            path: '/home',
                            name: 'home',
                            label: '管理员',
                            icon: 's-custom',
                            url: 'SystemManagement/Administrator.vue'
                        },
                        {
                            path: '/video',
                            name: 'video',
                            label: '监督员',
                            icon: 's-platform',
                            url: 'SystemManagement/SystemManagement.vue'
                        }
                    ],
                    token: Mock.Random.guid(),
                    message: '获取成功'
                }
            }
        } else {
            return {
                code: -999,
                data: {
                    message: '密码错误'
                }
            }
        }

    },
    menu1: {
        menu: [
            {
                path: '/MarketData',
                name: 'MarketData',
                label: '数据看板',
                icon: 'video-play',
                url: 'MarketManagement/MarketData.vue'
            },
            {
                path: '/MarketManagement',
                name: 'MarketManagement',
                label: '市场',
                icon: 's-home',
                url: 'MarketManagement/MarketManagement.vue'
            },
            {
                path: '/MarketDevice',
                name: 'MarketDevice',
                label: '设备',
                icon: 's-home',
                url: 'MarketManagement/MarketDevice.vue'
            },
            {
                path: '/Marketedit',
                name: 'Marketedit',
                url: 'MarketManagement/Marketedit.vue'
            },
        ],
    },
    menu2: {
        menu: [
            {
                path: '/marketSurveillance',
                name: 'marketSurveillance',
                label: '数据看板',
                icon: 'video-play',
                url: 'MarketSurveillance/marketSurveillance.vue'
            },
            {
                path: '/marketSurveillanceMarket',
                name: 'marketSurveillanceMarket',
                label: '市场',
                icon: 's-home',
                url: 'MarketSurveillance/marketSurveillanceMarket.vue'
            },
           
            {
                path: '/marketSurveillanceDevice',
                name: 'marketSurveillanceDevice',
                label: '设备',
                icon: 's-home',
                url: 'MarketSurveillance/marketSurveillanceDevice.vue'
            },
            {
                path: '/MarketSurveillanceEdit',
                name: 'MarketSurveillanceEdit',
                url: 'MarketSurveillance/MarketSurveillanceEdit.vue'
            },
        
        ],
    },

    menu3: {
        menu: [
            {
                path: '/admininistrator',
                name: 'admininistrator',
                label: '管理员',
                icon: 's-custom',
                url: 'SystemManagement/Administrator.vue'
            },
            {
                path: '/video',
                name: 'video',
                label: '监督员',
                icon: 's-platform',
                url: 'SystemManagement/SystemManagement.vue'
            }
        ],
    }
}