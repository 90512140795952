<template>
  <div class="app">
    <div class="login-cont">
      <div class="con-box">
        <el-form
          ref="form"
          label-width="70px"
          :inline="true"
          class="login-container"
          :model="form"
          :rules="rules"
        >
          <div class="tabs-top">
            <!-- <div class="tab-item">
              <span @click="cur = 0" :class="cur == 0 ? 'tabcheck' : 'tab'"
                >市场管理</span
              >
            </div>
            <div class="tab-item">
              <span @click="cur = 1" :class="cur == 1 ? 'tabcheck' : 'tab'"
                >市场监管</span
              >
            </div>
            <div class="tab-item">
              <span @click="cur = 2" :class="cur == 2 ? 'tabcheck' : 'tab'"
                >系统超管</span
              >
            </div> -->
            <div class="login_panel">
              <!-- tab栏目 -->
              <div class="newtab">
                <a
                  v-for="(item, index) in tabList"
                  :key="index"
                  class="tabs1"
                  :class="{ 'tab-current': index == currentIndex }"
                  @click="clickTab(index)"
                >
                  {{ item }}
                </a>
              </div>
              <!-- 线条 -->
              <div class="firstLine" :style="{ left: slideLeft }"></div>

              <!-- <transition enter-active-class="animated fadeIn"
							leave-active-class="animated fadeOut"
							mode="out-in">
					<component :is="componentName"></component> 
				</transition> -->
            </div>
          </div>

          <div class="login-content">
            <div class="el-form">
              <el-form-item label="用户名" prop="username">
                <el-input
                  v-model="form.username"
                  placeholder="请输入账号"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="form.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="submit" type="primary">登录</el-button>
              </el-form-item>
            </div>

            <!-- <div v-show="cur == 1" class="el-form">
              <el-form-item label="用户名" prop="username">
                <el-input
                  v-model="form.username"
                  placeholder="请输入账号2"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="form.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="submit" type="primary">登录</el-button>
              </el-form-item>
            </div>
            <div v-show="cur == 2" class="el-form">
              <el-form-item label="用户名" prop="username">
                <el-input
                  v-model="form.username"
                  placeholder="请输入账号3"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input
                  type="password"
                  v-model="form.password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="submit" type="primary">登录</el-button>
              </el-form-item>
            </div> -->
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import Mock from "mockjs";
import Cookie from "js-cookie";
import { getMenu } from "../api";

import { authCodeLogin } from "../utils/api";
// import { adminLogin } from "../utils/api";
import md5 from "../utils/md5";
import axios from "axios";
import util from "../utils/util";
import permissions from "../api/mockServeData/permission";

export default {
  data() {
    return {
      cur: 0,
      // content: [true, false, false],
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
      },
      tabList: ["市场管理", "市场监管", "系统超管"],
      tabLen: "",
      currentIndex: 0,
      slideLeft: "",
    };
  },
  computed: {
    tryme() {
      return 10 + this.show * 130 + "px";
    },
  },
  methods: {
    // 点击时的下划线跟随效果
    clickTab(index) {
      this.currentIndex = index;
      // 算出一个tab所占屏幕的宽度，除以2是为了让下划线待在tab标签宽度二分之一处，因为css中给line标签样式使用了绝对定位，并且设置了margin负值，所以这个位置可以让line正好在tab标签下面居中
      const left = 100 / this.tabLen / 2;
      console.log(left);
      // 一次滑动二分一tab标签值
      // 三个标签，可以看成把屏幕分成了6份，每人占2份
      // 第一个下划线，从第1份的地方开始，是0*2+1
      // 第二个下划线，从第3份的地方开始，是1*2+1
      // 第三个下划线，从第5份的地方开始, 是2*2+1
      this.slideLeft = left * (index * 2 + 0.5) + "%";
      console.log(this.tabLen);
    },
    // 刚进入页面没有点击时的下划线设置
    slideLineLeft() {
      this.tabLen = this.tabList.length;
      const left = 100 / this.tabLen / 2;
      this.slideLeft = left * (this.currentIndex * 2 + 0.5) + "%";

      console.log(this.slideLeft);
    },

    // 登录
    submit1() {
      // // token信息
      // const token = Mock.Random.guid()

      // 校验通过
      this.$refs.form.validate((valid) => {
        if (valid) {
          getMenu(this.form).then(({ data }) => {
            if (data.code === 20000) {
              // token信息存入cookie用于不同页面间的通信
              Cookie.set("token", data.data.token);

              // 获取菜单的数据，存入store中
              console.dir("返回数据：", data.data.menu);
              this.$store.commit("setMenu", data.data.menu);
              this.$store.commit("addMenu", this.$router);
              // 跳转到首页
              this.$router.push("/home");
            } else {
              this.$message.error(data.data.message);
            }
          });
        }
      });
    },
    /**
     * 提交登录信息
     */
    submit() {
      console.dir(
        "登录数据：" +
          "\n账号：" +
          this.form.username +
          "\n密码：" +
          this.form.password +
          "\n加密密码：" +
          md5(this.form.password)
      );
      var md5Password = md5(this.form.password);
      var data = {
        username: this.form.username,
        loginPassword: md5Password,
        updateInMarch: "1",
      };

      if (this.currentIndex === 0) {
        var data = {
          loginName: this.form.username,
          loginPassword: md5Password,
          deviceId: "Windows",
          updateInMarch: "1",
        };
        this.$api.loginMarketManager(data).then((res) => {
          console.dir("返回数据：", res);
          if (res.status === 200 && res.data.codeId === 1) {
            // token信息存入cookie用于不同页面间的通信;
            this.$cookie.set("token", res.data.dataObject.token);
            this.$cookie.set("key", res.data.dataObject.key);
            // 获取菜单的数据，存入store中
            this.$store.commit("setMenu", permissions.menu1.menu);
            this.$store.commit("addMenu", this.$router);
            // 跳转到首页
            this.$router.push("/MarketData");
          } else {
            this.$message.error(res.data.message);
          }
        });
      } else if (this.currentIndex === 1) {
        // bb8182c259d4d9eed61a9327fe4e3725dbcba9c75ad47f13
        var data = {
          monitorLoginName: this.form.username,
          monitorLoginPassword: md5Password,
          deviceId: "Windows",
          updateInMarch: "1",
        };
        this.$api.loginMarketMonitorManager (data).then((res) => {
          console.dir("返回数据：", res);
          if (res.status === 200 && res.data.codeId === 1) {
            // token信息存入cookie用于不同页面间的通信;
            this.$cookie.set("token", res.data.dataObject.token);
            this.$cookie.set("key", res.data.dataObject.key);
            // 获取菜单的数据，存入store中
            this.$store.commit("setMenu", permissions.menu2.menu);
            this.$store.commit("addMenu", this.$router);
            // 跳转到首页
            this.$router.push("/marketSurveillance");
          } else {
            this.$message.error(res.data.message);
          }
        });
      } else {
        // bb8182c259d4d9eed61a9327fe4e3725dbcba9c75ad47f13
        var data = {
          username: this.form.username,
          loginPassword: md5Password,
          updateInMarch: "1",
        };
        this.$api.adminLogin(data).then((res) => {
          console.dir("返回数据：", res);
          if (res.status === 200 && res.data.codeId === 1) {
            // token信息存入cookie用于不同页面间的通信;
            this.$cookie.set("token", res.data.dataObject.token);
            this.$cookie.set("key", res.data.dataObject.key);
            // 获取菜单的数据，存入store中
            this.$store.commit("setMenu", permissions.menu3.menu);
            this.$store.commit("addMenu", this.$router);
            // 跳转到首页
            this.$router.push("/admininistrator");
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
  },

  mounted() {
    this.slideLineLeft();
    // 如果不写这个函数，页面刚加载时默认tab的下划线位置是不对的
  },
};
</script>
<style lang="less" scoped>
.app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.login-cont {
  height: 100%;
  width: 100%;
  position: fixed;
  background-size: 100% 100%;
  background-image: url(../assets/images/login-bg.jpg) !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.con-box {
  height: 100%;
}
.login-container {
  position: absolute;
  box-sizing: border-box;
  width: 440px;
  height: 493px;
  padding: 50px 40px;
  top: 18%;
  right: 16.5%;
  background-image: url("../assets/images/form-bg-5434fdbe.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.login_panel {
  width: 400px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.newtab {
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  text-align: center;
  position: relative;
}
.newtab a {
  width: 130px;
  display: inline-block;
}
.firstLine {
  width: 65px;
  height: 3px;
  background-color: #0396ff;
  margin: 0;
  padding: 0;
  position: relative;
  transition: all 0.5s;
}

.tabs1 {
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
}
.tab1 {
  font-size: 18px;
  line-height: 0.88rem;
  color: #c3c6cd;
  margin-left: -10px;
}

.tab-current {
  font-size: 22px;
  color: #0396ff;
}
.line {
  width: 10px;
  background-color: #0396ff;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  top: 5px;
  border-radius: 0.04rem;
  transition: all 0.3s;
}

.tabs-top {
  display: flex;
  justify-content: center;
}

.tab-item {
  display: flex;
  width: 50%;
  height: 45px;
  justify-content: center;
}

span {
  text-align: center;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  display: inline-block;
}
.el-form {
  margin-top: 50px;
}
.el-form-item__label-wrap {
  margin-left: 0px !important;
}
.el-input {
  width: 250px;
  margin-bottom: 10px;
}
.el-button {
  width: 360px;
  height: 50px;
  font-size: 18px !important;
  font-weight: 700;
  color: #fff;
  margin-top: 40px;
  text-align: center;
  background-color: #2c3e50;
}

.tabcheck {
  color: #000;
}
.tab {
  color: #7a7878;
}
</style>