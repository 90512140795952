<template>
  <div class="query">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="姓名：">
        <el-input
          v-model="formInline.monitorName"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="账号：">
        <el-input
          v-model="formInline.monitorLoginName"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          style="background: #ffffff; color: #000; border-color: #dddddd"
          @click="onRest"
          >重置</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onCheck">查询</el-button>
      </el-form-item>
    </el-form>

    <div class="manage">
      <el-dialog
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div
          slot="title"
          class="header-title"
          :style="{
            background: 'white',
            color: 'black',
            'font-weight': 'bold',
          }"
        >
          <div
            style="
              border-left: 4px solid rgb(54, 161, 233);
              width: 90px;
              font-weight: bold;
              text-align: center;
            "
          >
            {{ dialogTitle }}
          </div>
        </div>

        <!-- 用户的表单信息 -->
        <el-form ref="form" :rules="rules" :model="form" label-width="160px">
          <el-form-item label="市场监管名称：" prop="monitorGroupName">
            <el-input
              placeholder="请输入市场监管名称"
              v-model="form.monitorGroupName"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人：" prop="monitorName">
            <el-input
              placeholder="请输入监管人姓名"
              v-model="form.monitorName"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="monitorPhoneNo">
            <el-input
              placeholder="请输入联系电话"
              v-model="form.monitorPhoneNo"
            ></el-input>
          </el-form-item>
          <el-form-item label="登录账号：" prop="monitorLoginName">
            <el-input
              placeholder="请输入登录账号"
              v-model="form.monitorLoginName"
            ></el-input>
          </el-form-item>

          <el-form-item label="登录密码：" prop="monitorLoginPassword">
            <el-input
              placeholder="admin123456"
              :disabled="true"
              v-model="admin123456"
              value="admin123456"
            ></el-input>
          </el-form-item>
          <el-form-item label="管辖区域：" prop="provinceCode">
            <el-cascader
              style="width: 100%"
              size="large"
              placeholder="请选择省、市、区"
              :options="regionData"
              v-model="form.provinceCode"
              expand-trigger="hover"
              ref="cascaderAddr"
              :key="modalKey"
              @change="addressChange"
            >
            </el-cascader>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
      <div class="manage-header">
        <el-button @click="handleAdd" type="primary"> + 新增 </el-button>
      </div>
    </div>

    <div class="common-tabel">
      <el-table :data="tableData" height="620px">
        <el-table-column prop="monitorGroupName" label="市场监管名称">
        </el-table-column>
        <el-table-column prop="monitorName" label="监管人姓名">
        </el-table-column>
        <el-table-column
          prop="monitorPhoneNo"
          label="联系电话"
        ></el-table-column>
        <el-table-column prop="provinceCode" label="管辖区域">
          <template slot-scope="scope">
            <p>
              {{
                codeToText[scope.row.provinceCode.slice(0, 2)] +
                " " +
                codeToText[scope.row.cityCode.slice(0, 4)] +
                " " +
                codeToText[scope.row.countryCode]
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="monitorLoginName" label="登录账号">
        </el-table-column>

        <el-table-column prop="addr" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="restPassword(scope.row)"
              >重置密码</el-button
            >
            <el-button size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <!-- <el-pagination
          style="margin-top: 20px"
          layout="->,prev, pager, next"
          :total="total"
          @current-change="handlePage"
        >
        </el-pagination> -->
        <!--分页按钮-->
        <el-pagination
          background
          layout="prev, pager, next,jumper"
          :total="total"
          @current-change="handlePage"
          :current-page="pageData.page"
          :page-size="pageData.size"
          style="text-align: center"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  <script>
import { getUser, addUser, editUser, delUser } from "../../api";
import { regionData, codeToText } from "element-china-area-data";
import md5 from "../../utils/md5";
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不可为空"));
      } else {
        if (value !== "") {
          var reg = /^1[3-9]\d{9}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的手机号码"));
          }
        }
        callback();
      }
    };
    return {
      formInline: {
        monitorName: "",
        monitorLoginName: "",
      },
      regionData,
      codeToText,
      modalKey: 0,
      monitorAddress: [],
      dialogVisible: false,
      form: {
        monitorLoginName: "",
        monitorLoginPassword: "",
        monitorGroupName: "",
        monitorPhoneNo: "",
        monitorName: "",
        rootmonitorId: "",
        provinceCode: "",
      },
      editForm: {
        monitorLoginName: "",
        monitorLoginPassword: "",
        monitorGroupName: "",
        monitorPhoneNo: "",
        monitorName: "",
        rootmonitorId: "",
        provinceCode: "",
      },
      rules: {
        monitorGroupName: [{ required: true, message: "请输入市场监管名称" }],
        monitorName: [{ required: true, message: "请输入姓名" }],
        provinceCode: [
          {
            type: "array",
            required: true,
            message: "请选择管辖区域",
            trigger: "change",
          },
        ],
        monitorPhoneNo: [
          { required: true, message: "请输入联系电话" },
          {
            validator: checkMobile,
            min: 11,
            max: 11,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        monitorLoginName: [{ required: true, message: "请输入登录账号" }],
      },
      tableData: [],
      modalType: 0, // 0表示新增的弹窗， 1表示编辑
      dialogTitle: "",
      total: 0, //当前的总条数
      pageData: {
        page: 1,
        limit: 10,
      },
      userForm: {
        name: "",
      },
    };
  },
  methods: {
    /**
     * 获取市场监管员列表数据
     */
    getList(page) {
      this.tableData = []
      var data = {
        token: this.$cookie.get("token"),
        monitorLoginName: "",
        monitorName: "",
        page: page,
        size: 10,
        updateInMarch: "1",
      };
      this.$api.getMarketMonitorManagerByPage(data).then((res) => {
        console.dir("返回数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.tableData = res.data.dataObject.data;
          // let arr = [];
          // arr.push(provinceCode)
          // arr.push(cityCode)
          // arr.push(countryCode)
          // this.monitorAddress = arr;
          this.total = res.data.dataObject.pageTotal * 10;
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },

    // 选择页码的回调函数
    handlePage(val) {
      this.pageData.page = val;
      console.dir("返回数据：", val);
      this.getList(val);
    },

    /** 重置搜索选项*/
    onRest() {
      this.formInline.monitorLoginName = "";
      this.formInline.monitorName = "";
      this.getList(1);
    },
    /** 列表模糊查询*/
    onCheck() {
      var data = {
        token: this.$cookie.get("token"),
        monitorLoginName: this.formInline.monitorLoginName,
        monitorName: this.formInline.monitorName,
        page: 1,
        size: 10,
        updateInMarch: "1",
      };
      this.$api.getMarketMonitorManagerByPage(data).then((res) => {
        console.dir("返回数据：", res);
        if (res.status === 200 && res.data.codeId === 1) {
          this.tableData = res.data.dataObject.data;
          this.total = res.data.dataObject.pageTotal * 10;
        } else {
          if (res.data.message === "超出页数") {
            // this.$message.error("列表中没有数据");
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },

    /**
     * 重置密码
     */
    restPassword(row) {
      this.$confirm("确定要重置此用户密码为admin123456?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.dir(row);

          var data = {
            token: this.$cookie.get("token"),
            monitorId: row.monitorId,
            monitorLoginPassword: md5("admin123456"),
            // monitorLoginName: "",
            // monitorName: "",
            // monitorPhoneNo: "",
            // monitorGroupName: "",
          };
          this.$api.updateMarketMonitorManagerInfo(data).then((res) => {
            console.dir("返回数据：", res);
            if (res.status === 200 && res.data.codeId === 1) {
              this.$message({
                type: "success",
                message: "重置密码成功!",
              });
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    addressChange(value) {
      console.dir("地址1", value);
      this.monitorAddress = value;

      var name = "";
      // this.monitorAddress.map((item) => (name += CodeToText[item] + "")); //将省市区三个拼接一起
      // this.addtions.names = name;
      // console.log("11", this.addtions.names);
      //运用了CodeToText把编码转成了中文
      this.addForm.province = CodeToText[this.monitorAddress[0] + "0000"];
      this.addForm.county = CodeToText[this.monitorAddress[1] + "00"];
      this.addForm.area = CodeToText[this.monitorAddress[2]];

      console.log(this.addForm);
    },

    /**新增监管员 */
    handleAdd() {
      this.form = {
        monitorLoginName: "",
        monitorLoginPassword: "",
        monitorGroupName: "",
        monitorPhoneNo: "",
        monitorName: "",
        rootmonitorId: "",
      };
      this.modalType = 0;
      this.dialogTitle = "新增用户";
      this.dialogVisible = true;
    },
    /**编辑监管员 */
    handleEdit(row) {
      console.dir("数据地址", row);
      // 注意需要对当前行数据进行深拷贝，否则会出错
      this.form = JSON.parse(JSON.stringify(row));
      this.editForm = JSON.parse(JSON.stringify(row));
      var p = this.editForm.provinceCode;
      this.form.provinceCode = [];
      this.form.provinceCode.push(p.slice(0, 2));
      this.form.provinceCode.push(this.form.cityCode.slice(0, 4));
      this.form.provinceCode.push(this.form.countryCode.slice(0, 6));

      console.dir("地址3", this.editForm.provinceCode);
      this.modalType = 1;
      this.dialogTitle = "编辑用户";
      this.dialogVisible = true;

      this.monitorAddress = [
        row.provinceCode.slice(0, 2),
        row.cityCode.slice(0, 4),
        row.countryCode.slice(0, 6),
      ];
      this.modalKey++; //改变key值，组件重新渲染，实现回填功能
      console.dir("数据1", this.monitorAddress);
    },

    // 提交用户表单
    submit() {
      this.$refs.form.validate((valid) => {
        console.log("地址", this.form.provinceCode);
        if (valid) {
          // 后续对表单数据的处理
          if (this.modalType === 0) {
            // 新增监管
            var data = {
              token: this.$cookie.get("token"),
              monitorLoginPassword: md5("admin123456"),
              monitorLoginName: this.form.monitorLoginName,
              monitorName: this.form.monitorName,
              monitorPhoneNo: this.form.monitorPhoneNo,
              monitorGroupName: this.form.monitorGroupName,
              provinceCode: this.monitorAddress[0] + "0000",
              cityCode: this.monitorAddress[1] + "00",
              countryCode: this.monitorAddress[2],
            };

            console.dir("上傳", data);

            this.$api.createMarketMonitorManagerAccount(data).then((res) => {
              console.dir("返回数据：", res);
              if (res.status === 200 && res.data.codeId === 1) {
                this.$message({
                  type: "success",
                  message: "新增监管员信息成功!",
                });
                this.getList(1);
              } else {
                this.$message.error(res.data.message);
              }
            });
          } else {
            console.dir("dizhi:", this.monitorAddress);
            // 编辑
            var data = {
              token: this.$cookie.get("token"),
              monitorId: this.form.monitorId,
              monitorLoginPassword: md5("admin123456"),
              monitorLoginName: this.form.monitorLoginName,
              monitorName: this.form.monitorName,
              monitorPhoneNo: this.form.monitorPhoneNo,
              monitorGroupName: this.form.monitorGroupName,
              provinceCode: this.monitorAddress[0] + "0000",
              cityCode: this.monitorAddress[1] + "00",
              countryCode: this.monitorAddress[2],
            };
            if (this.form.monitorLoginName == this.editForm.monitorLoginName) {
              this.$delete(data, "monitorLoginName");
            }
            this.$api.updateMarketMonitorManagerInfo(data).then((res) => {
              console.dir("返回数据：", res);
              if (res.status === 200 && res.data.codeId === 1) {
                this.$message({
                  type: "success",
                  message: "更新监管员信息成功!",
                });
                this.getList(this.pageData.page);
                this.$refs.form.resetFields();
              } else {
                this.$message.error(res.data.message);
              }
            });
          }

          // 清空表单的数据
          this.$refs.form.resetFields();
          // 关闭弹窗
          this.dialogVisible = false;
        }
      });
    },

    handleDelete(row) {
      this.$confirm("确定要删除该用户么", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.dir("1", row);

          var data = {
            token: this.$cookie.get("token"),
            monitorId: row.monitorId,
          };
          this.$api.deleteMarketMonitorManagerInfo(data).then((res) => {
            console.dir("返回数据：", res);
            if (res.status === 200 && res.data.codeId === 1) {
              this.$message({
                type: "success",
                message: "删除用户成功",
              });
              this.getList(1);
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 弹窗关闭的时候
    handleClose() {
      console.dir("d", this.modalKey);
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    cancel() {
      // this.$refs.form.resetFields();
      this.monitorAddress = [];
      this.handleClose();
    },
  },
  mounted() {
    this.getList(1);
  },
};
</script>
  <style lang="less" scoped>
.manage {
  height: 90%;
  .el-dialog__title {
    color: brown;
  }
  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .common-tabel {
    position: relative;
    height: calc(100% - 62px);
    overflow: auto;
    .pager {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
  